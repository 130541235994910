import {
    FETCH_BOOKINGS_START,
    FETCH_BOOKINGS_SUCCESS,
    FETCH_BOOKINGS_FAILURE,
    EDIT_BOOKING,
    BOOKING_SELECTED,
    DELETE_BOOKING,
  } from "../types";
  import history from "../../../history";
  import { BookingInstance } from "../../../config";
  
  

  const fetchBookingsStart = () => {
    return {
      type: FETCH_BOOKINGS_START,
    };
  };
  
  const fetchBookingsSuccess = (bookings) => {
    return {
      type: FETCH_BOOKINGS_SUCCESS,
      bookings,
    };
  };
  
  const fetchBookingsFailure = (error) => {
    return {
      type: FETCH_BOOKINGS_FAILURE,
      error,
    };
  };
  
  const fetchBookingDetails = () => {
    const response = BookingInstance.get("booking/");
    return response;
  };
  
  export const fetchBookings = () => (dispatch) => {
    dispatch(fetchBookingsStart());
    return fetchBookingDetails().then(
      (response) => dispatch(fetchBookingsSuccess(response.data.data)),
      (error) => dispatch(fetchBookingsFailure(error))
    );
  };
  
  export const selectBooking = (id) => {
    return {
      type: BOOKING_SELECTED,
      id: id,
    };
  };
  
  export const deleteBooking = (id) => {
    return async (dispatch) => {
      const response = await BookingInstance.delete(`booking/${id}/`);
      dispatch({ type: DELETE_BOOKING, payload: response.data });
      history.push("/");
    };
  };
  
  export const editBooking = (id) => {
    return async (dispatch) => {
      const response = await BookingInstance.get(`booking/${id}/`);
      dispatch({ type: EDIT_BOOKING, payload: response.data });
      history.push({
        pathname: "/BookingsMGT",
        prePopulate: response.data.data,
        populate: true,
      });
    };
  };


  