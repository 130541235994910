
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { bindActionCreators } from "redux";
import {
  fetchPosts,
  editPost,
  selectPost,
  deletePost,
} from "../../../../../Redux/actions/Posts/fetchPostActions";
import { showNewsModal, hideNewsModal } from "../../../../../Redux/actions/UI/toggleModalAction";
import DataTable from "react-data-table-component";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import arrow from "../../../../../assets/arrow.svg";
import Edit from "../../../../../assets/edit.svg";
import Delete from "../../../../../assets/delete.svg";
import orderBy from "lodash/orderBy";
import Dropdown from "react-bootstrap/Dropdown";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";


export class PostsList extends Component {
  constructor() {
    super();
    this.state = {
      filterText: "",
    };
  }

  async componentDidMount() {
    await this.props.fetchPosts();
    console.log(this.props.fetchPosts());
  }

  handleChange = (event) => {
    this.setState({ filterText: event.target.value });
  };

  modalHandler() {
    if (this.props.newsModal) {
      return (
        <MDBContainer
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "0px 0px 0px #888888",
            padding: "2%",
          }}
        >
          <MDBModal isOpen={this.props.newsModal} toggle={this.props.newsModal} centered size="sm">
            <MDBModalHeader>Delete</MDBModalHeader>
            <MDBModalBody>Are you sure ?</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="light" onClick={this.props.hideNewsModal} size="sm">
                Cancel
              </MDBBtn>
              <MDBBtn
                color="danger"
                onClick={() => {
                  if (this.selectedPost !== null) {
                    this.props.deletePost(this.props.selectedPost.id);
                    this.props.hideNewsModal();
                  }
                }}
                size="sm"
              >
                Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
    }
  }

  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field]) {
        return row[field].toLowerCase();
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  actionOptions = (row) => {
    return (
      <>
        <Dropdown.Item onClick={() => this.props.editPost(row.id)}>
          <div className="table-edit">
            <img src={Edit} alt="" /> Edit
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            this.props.selectPost(row.id);
            this.props.showNewsModal();
          }}
        >
          <div className="table-delete">
            <img src={Delete} alt="" /> Delete
          </div>
        </Dropdown.Item>
      </>
    );
  };

  renderPosts() {
    if (this.props.posts !== null && this.props.posts.isLoading === false) {
      return this.props.posts.posts.posts.map((post) => {
        return {
          id: post.id,
          title: post.title,
          published: post.created_date,
          edit: (
            <MDBBtn color="primary" size="sm" onClick={() => this.props.editPost(post.id)}>
              Edit
            </MDBBtn>
          ),
          delete: (
            <MDBBtn
              color="danger"
              size="sm"
              onClick={() => {
                this.props.selectPost(post.id);
                this.props.showNewsModal();
              }}
            >
              Delete
            </MDBBtn>
          ),
        };
      });
    }
  }

  


    render() {
      let filteredData;
      const { filterText } = this.state;
      if (this.props.posts !== null && this.props.posts.isLoading === false) {
        filteredData = this.renderPosts().filter(
          (item) =>
            // item.id.toLowerCase().includes(filterText.toLowerCase()) ||
            item.title.toLowerCase().includes(filterText.toLowerCase()) ||
            item.published.toLowerCase().includes(filterText.toLowerCase()) ||
            item.email.toLowerCase().includes(filterText.toLowerCase())
        );
      }

    const columns = [
      {
        name: "ID",
        selector: "id",
        grow: 1,
        sortable: true,
      },
      {
        name: "Title",
        selector: "title",
        grow: 1,
        sortable: true,
      },
      {
        name: "Published",
        selector: "published",
        grow: 1,
        sortable: true,
      },
      {
        cell: (row) => (
          <Dropdown>
            <Dropdown.Toggle id="dropdown-action-user-org" style={{marginTop:"13px"}}>
              <img src={arrow} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="edit-table-wrapper">{this.actionOptions(row)}</Dropdown.Menu>
          </Dropdown>
        ),
        selector: "action",
        allowOverflow: true,
        button: true,
        right: true,
      },
    ];

    if (this.props.posts !== null && this.props.posts.isLoading === true) {
      return <Spinner />;
    } else {
      return (
        <div >
          <div className="white-background-offers-list">
          <row>
          <span className="list-title">NEWS LIST</span>
            <Link to="/NewPost">
              <button type="button" data-testid="org-button" style={{marginLeft:"-60px"}}className="dashboard-register-button">
               New Post
              </button>
            </Link>
          </row>
          <div className="table-search-wrapper">
            <input
              className="table-search"
              placeholder="   Search Posts"
              value={this.filter}
              onChange={this.handleChange}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination={true}
            paginationPerPage={4}
            paginationRowsPerPageOptions={[4, 10, 15]}
            sortFunction={this.customSort}
          />
          </div>
          <div>{this.modalHandler()}</div>
        </div>
      );
    }
}
}

const mapStateToProps = (state) => {
  return {
   newsModal: state.toggleNewsModal.show,
   posts : state.posts,
   security: state.security,
  //  selectPost: state.selectPost,
   selectedPost: state.selectedPost,
   editPost: state.editPost,
   deletedPost: state.deletedPost,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchPosts, editPost, selectPost, deletePost, showNewsModal, hideNewsModal },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PostsList);
