import React, { Component } from "react";
import { BookingInstance } from "../../../../../config";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showModal, hideModal } from "../../../../../Redux/actions/UI/toggleModalAction";
import {
  showDispatchModal,
  hideDispatchModal,
} from "../../../../../Redux/actions/UI/toggleDispatchModalAction";
import { selectTechnician } from "../../../../../Redux/actions/Technicians/fetchTechnicianAction";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import ErrorModal from "../../../../../components/UI/Modal/ErrorModal";
import { Link } from "react-router-dom";

class Notifications extends Component {
  constructor() {
    super();
    this.state = {
      bookings: [],
      id: [],
      loading: false,
      show: false,
      error: {}
    };
  }

  getNotificationData = async () => {
    this.setState({ loading: true });
    try {
      let bookings = await BookingInstance.get(`booking/`);
      this.setState({ bookings: bookings.data.data, loading: false });
    } catch (error) {
      console.log("Error during consuming employees API");
      this.setState({ error: error, show: true})
    }
  };

  componentDidMount() {
    this.getNotificationData();
  }

  handleClose = () => {
    this.setState({show: false});
  };

  render() {
    if (this.state.loading) {
      return <Spinner />;
    } else {
      return (
        <React.Fragment>
          <div  className="dash-secondary-heading">LATEST BOOKINGS</div>
            <ul className="notifications-list">
              {this.state.bookings.map((booking) =>
                  <li key={booking.id}>
                    <span className="booking-id"> ID: {"  "}{booking.id}</span>
                    <span className="tech-name" title={booking.technician.first_name}>{booking.technician.first_name}  </span>
                    <span className="customer-name-notif" title={booking.customer.user.first_name}> {booking.customer.user.first_name}</span>
                    <span className="notification-time" title={booking.from_date +" TO "+ booking.to_date}>{moment(booking.from_date).format("DD.MM")}-{moment(booking.to_date).format("MM.DD.YYYY")}</span>
                  </li>
                )
              } 
            </ul>
          <div style={{position: "absolute", bottom: "12px", right: "12px"}} className="btn-group dashboard-buttons-container">
            <Link
                to="/BookingsMGT">
                <button
                  type="button"
                  data-testid="user-button"
                  className=" dashboard-register-button"
                  id="btn-booking-dashboard"
                >
                  New Booking
                </button>
              </Link>
              <Link
                to={{ pathname: "/Bookings", populate: false }}>
                <button
                  type="button"
                  data-testid="user-button"
                  className="dash-view-button"
                  id="btn-booking-dashboard-view"
                >
                  View List
                </button>
              </Link>
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    bookings: state.bookings,
    modal: state.toggleModal.show,
    dispatchModal: state.toggleDispatchModal.showDispatch,
    selectedTechnician: state.selectTechnician,
    users: state.users
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      showDispatchModal,
      hideDispatchModal,
      selectTechnician,
      
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
