import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  showMembers,
  showRegister,
  showTechnicians,
  showBookings,
  closeRegisterMember,
  clearCss,
  removeClearCss,
} from "../../Redux/actions/UI/sideMenuActions";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUserFriends,
  faTools,
  faCalendarAlt,
  faIdCardAlt,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";
import marvelImg from "../../assets/images/marvel-icon.png";

class Sidemenu extends React.Component {
  showRegisterMembers = false;
  showMembers = false;
  showTechnicians = false;
  showBookings = false;

  registerMembers = () => {
    this.showMembers = false;
    if (this.showRegisterMembers) {
      this.showRegisterMembers = false;
    } else {
      this.showRegisterMembers = true;
    }
  };

  adminDashboardClick = () => {
    this.props.closeRegisterMember();
    this.showRegisterMembers = false;
    this.showMembers = false;
  };

  // adminAnalyticsClick = () => {
  //   this.props.closeRegisterMember();
  //   this.props.clearCss();
  //   this.showRegisterMembers = false;
  //   this.showMembers = false;
  // };

  // Psap sidemenu options
  technicians = () => {
    this.showTechnicians = false;
    if (this.showTechnicians) {
      this.showTechnicians = false;
    } else {
      this.showTechnicians = true;
    }
  };

  psapDashboardClick = () => {
    this.props.clearCss();
    this.props.sideMenu.showTechnicians = false;
  };

  psapAnalyticsClick = () => {
    this.props.clearCss();
    this.props.sideMenu.showTechnicians = false;
  };

  menuOptions = () => {
    // let role;
    // if (
    //   this.props.security.user.user_roles &&
    //   this.props.security.user.user_roles[0] !== undefined &&
    //   this.props.security.user.user_roles[0] === "HIGH_COMMANDER"
    // ) {
    //   role = this.props.security.user.user_roles[0];
    // } else if (
    //   this.props.security.user.user_roles &&
    //   this.props.security.user.user_roles[0] !== undefined &&
    //   this.props.security.user.user_roles[0] === "HOSPITAL_COMMANDER"
    // ) {
    //   role = this.props.security.user.user_roles[0];
    // } else if (
    //   this.props.security.user.user_roles &&
    //   this.props.security.user.user_roles[0] !== undefined &&
    //   this.props.security.user.user_roles[0] === "EMS"
    // ) {
    //   if (
    //     this.props.security.user.incidentRole &&
    //     this.props.security.user.incidentRole !== undefined &&
    //     this.props.security.user.incidentRole === "PSAP"
    //   ) {
    //     role = this.props.security.user.incidentRole;
    //   } else if (
    //     this.props.security.user.incidentRole &&
    //     this.props.security.user.incidentRole !== undefined &&
    //     this.props.security.user.incidentRole === "FIELD_COMMANDER"
    //   ) {
    //     role = this.props.security.user.incidentRole;
    //   } else {
    //     role = this.props.security.user.user_roles[0];
    //   }
    // }
    let menuLinks = null;
    // switch (role) {
      // case "HIGH_COMMANDER":
        menuLinks = (
          <React.Fragment>
             <img id="header-logo" alt="marvel-logo" src={marvelImg} />
            <ul className="sidemenu-ul">
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/Dashboard"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li"
                  id="sidemenu-dashboard"
                  onClick={this.adminDashboardClick}
                >
                  <FontAwesomeIcon icon={faHome} size="sm" className="fontawesome-sidemenu" />
                  Dashboard
                </li>
              </NavLink>
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/Bookings"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li"
                  id="sidemenu-analytics"
                  onClick={this.adminAnalyticsClick}
                >
                  <FontAwesomeIcon icon={faCalendarAlt} size="sm" className="fontawesome-sidemenu" />
                  Bookings
                </li>
              </NavLink>
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/CustomerList"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li"
                  id="sidemenu-analytics"
                  onClick={this.adminAnalyticsClick}
                >
                  <FontAwesomeIcon icon={faUserFriends} size="sm" className="fontawesome-sidemenu" />
                  Customers
                </li>
              </NavLink>
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/AdminList"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li"
                  id="sidemenu-analytics"
                  onClick={this.adminAnalyticsClick}
                >
                  <FontAwesomeIcon icon={faIdCardAlt} size="sm" className="fontawesome-sidemenu" />
                  Admins
                </li>
              </NavLink>
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/TechnicianMGT"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li"
                  id="sidemenu-analytics"
                  onClick={this.adminAnalyticsClick}
                >
                  <FontAwesomeIcon icon={faTools} size="sm" className="fontawesome-sidemenu" />
                  Technicians
                </li>
              </NavLink>
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/Offers-News"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li"
                  id="sidemenu-analytics"
                  onClick={this.adminAnalyticsClick}
                >
                  <FontAwesomeIcon icon={faFileUpload} size="sm" className="fontawesome-sidemenu" />
                  News & Offers
                </li>
              </NavLink>
  

            </ul>
          </React.Fragment>
        );
        return menuLinks; 
  };

  // Add conditional jsx for cis sidemenu
  render() {
    return <div id="sidemenu">{this.menuOptions()}</div>;
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
  sideMenu: state.sideMenu,
  sideMenuClear: state.sideMenuClear,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { showRegister, showMembers, showTechnicians, showBookings, closeRegisterMember, clearCss, removeClearCss },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu);
