import {
    FETCH_BOOKINGS_REGISTRATION_START,
    FETCH_CUSTOMERS_REGISTRATION_SUCCESS,
    FETCH_BOOKINGS_REGISTRATION_FAILURE,
  } from "../types";
  import { BookingInstance } from "../../../config";
  
  

  const fetchBookingsStart = () => {
    return {
      type: FETCH_BOOKINGS_REGISTRATION_START,
    };
  };
  
  const fetchBookingsSuccess = (bookings) => {
    return {
      type: FETCH_CUSTOMERS_REGISTRATION_SUCCESS,
      bookings,
    };
  };
  
  const fetchBookingsFailure = (error) => {
    return {
      type: FETCH_BOOKINGS_REGISTRATION_FAILURE,
      error,
    };
  };
  
  const fetchBookingDetails = async () => {
    return BookingInstance.get("booking-request/");
  };
  
  export const fetchBookingsRegistration = () => (dispatch) => {
    dispatch(fetchBookingsStart());
    return fetchBookingDetails().then(
      (response) => dispatch(fetchBookingsSuccess(response.data.data)),
      (error) => dispatch(fetchBookingsFailure(error))
    );
  };
  


  