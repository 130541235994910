import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

import { connect } from "react-redux";

class ErrorModal extends Component {
  message = "";
  status = "";
  render() {
    let errorStatusSwitcher = () => {
      if (this.props.error.response) {
        console.log(this.props.error)
        switch (this.props.error.response.status) {
          case 400:
            this.message = "Please try again";
            return "Bad Request";
          case 401:
            this.status = "Login Failed";
            this.message = "Try again";
            break;
          case 403:
            this.message = "Try again";
            return "No Access Allowed";
          case 404:
            this.message = "Page not found"
            return "The resource does not exist";
          case 408:
            return "Connection Timeout";
          case 413:
            this.message = "The file is too big. Maximum size is 10MB.";
            return "Please try again";
          case 1212:
            return "Something went wrong";
          case 500:
            this.message = "Try again";
            return "Something went wrong";
          default:
            this.message = "Please, try again";
         }
      }else {
        this.message = "Try again"
        return "Network error"
      }
    };

    let errorsStatus = () => {
      if(this.props.errors.response.status === 401) {
            // this.status = "401";
            this.message = "Login Failed"
            return "Please try again"
    } else {
      return "problem"
    }
  }
    
    return (
      <div>
        {this.props.show === true ? (
          <Modal id="errorModal" show={this.props.show} onHide={this.props.handleClose}>
            <Modal.Header closeButton id="errorModalHeader">
              <Modal.Title id="errorModalTitle">
                {this.props.error ? errorStatusSwitcher() : errorsStatus()} 
                {this.status}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body id="errorModalBody" style={{fontWeight:"500",fontSize:16}}>{this.message}{this.props.response}</Modal.Body>
            <Modal.Footer id="errorModalFooter">
              <Button className="btn-danger" onClick={this.props.handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
})

export default connect(mapStateToProps) (ErrorModal);

