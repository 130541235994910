import { DELETE_BOOKING } from "../../actions/types";

export default (state = null, action) => {
  switch (action.type) {
    case DELETE_BOOKING:
      return {
        ...state,
        deleteBooking: action,
      };
    default:
      return state;
  }
};
