import { authInstance, UserInstance } from "../../../config";
import { GET_ERRORS, SET_CURRENT_USER, UPDATE_CURRENT_USER } from "../types";
import jwt_decode from "jwt-decode";

export const createNewUser = (newUser, history) => async (dispatch) => {
  try {
    await UserInstance.post("user/", newUser);
    history.push("/ResourceMGT");
    dispatch({
      type: GET_ERRORS,
      payload: null,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const login = (LoginRequest) => async (dispatch) => {
  try {
    // post => Login Request
    const res = await authInstance.post("login/", LoginRequest);
    // extract token from res.data
    const token = res.data.data.access;
    const refreshToken = res.data.data.refresh;
    // store the token in the localStorage
    localStorage.setItem("jwtToken", token);
    localStorage.setItem("jwtRefresh", refreshToken);
    // decode token on React
    const decoded = jwt_decode(token);
    console.log('TOKEN', decoded)
    // dispatch to our securityReducer
    dispatch({
      type: SET_CURRENT_USER,
      payload: decoded,
      tokenExp: decoded.exp,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err,
    });
  }
};

export const updateUser = (token) => async (dispatch) => {
  try {
    let newToken = await authInstance.post("http://167.71.60.27:2021/api/login/refresh/", {
      refresh: token,
    });
    const newDecodedToken = jwt_decode(newToken.data.data[0].access);
    dispatch({
      type: UPDATE_CURRENT_USER,
      payload: newDecodedToken,
      tokenExp: newDecodedToken.exp,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  dispatch({
    type: SET_CURRENT_USER,
    payload: null,
    tokenExp: null,
  });
};
