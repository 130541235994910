import {
    FETCH_OFFERS_START,
    FETCH_OFFERS_SUCCESS,
    FETCH_OFFERS_FAILURE,
    EDIT_OFFER,
    OFFER_SELECTED,
    DELETE_OFFER,
  } from "../types";
  import history from "../../../history";
  import { OfferInstance} from "../../../config";
  
  const fetchOffersStart = () => {
    return {
      type: FETCH_OFFERS_START,
    };
  };
  
  const fetchOffersSuccess = (offers) => {
    return {
      type: FETCH_OFFERS_SUCCESS,
      offers,
    };
  };
  
  const fetchOffersFailure = (error) => {
    return {
      type: FETCH_OFFERS_FAILURE,
      error,
    };
  };
  
  const fetchOfferDetails = () => {
    const response = OfferInstance.get("offers/");
    return response;
  };
  
  export const fetchOffers = () => (dispatch) => {
    dispatch(fetchOffersStart());
    return fetchOfferDetails().then(
      (response) => dispatch(fetchOffersSuccess(response.data.data)),
      (error) => dispatch(fetchOffersFailure(error))
    );
  };
  
  export const selectOffer = (id) => {
    return {
      type: OFFER_SELECTED,
      id: id,
    };
  };
  
  export const deleteOffer = (id) => {
    return async (dispatch) => {
      const response = await OfferInstance.delete(`offers/${id}/`);
      dispatch({ type: DELETE_OFFER, payload: response.data });
      history.push("/");
    };
  };
  
  export const editOffer = (id) => {
    return async (dispatch) => {
      const response = await OfferInstance.get(`offers/${id}/`);
      dispatch({ type: EDIT_OFFER, payload: response.data});
      history.push({
        pathname: "/NewOffer",
        prePopulate: response.data.data,
        populate: true,
      });
    };
  };
  