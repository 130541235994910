import React, { Component } from "react";
import { Router } from "react-router-dom";
import Routes from "../src/Routes";
import { connect } from "react-redux";

import Sidemenu from "./components/UI/Sidemenu";
import HeaderTest from "./components/UI/HeaderTest";
import history from "./history";
import SideDrawer from "./components/UI/Navigation/SideDrawer/SideDrawer";
import Backdrop from "./components/UI/Navigation/Backdrop/Backdrop";

class App extends Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let sideDrawer;
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
      sideDrawer = <SideDrawer />;
    }
    const { validToken, user } = this.props.security;
    return (
      <Router history={history}>
        <div className="container-fluid" id="app-container">
          <div className="row" id="app-row-header">
            <div className="col-12" id="header-col" >
              <HeaderTest drawerClickHandler={this.drawerToggleClickHandler} />
              {sideDrawer}
              {backdrop}
            </div>
          </div>
          {validToken && user ? (
            <div className="row" id="app-row-content">
              <div className="col-2" id="sidemenu-col">
                <Sidemenu />
              </div>
              <div className="col-10 body-content" id="app-body">
                <div id="body-container body-content" className="body-content-inner">
                  <Routes />
                </div>
              </div>
            </div>
          ) : (
            <div className="row" id="app-row-content">
              <div className="col" id="sidemenu-hidden"></div>
              <div className="col-12 body-content pl-0">
                <div id="body-container body-content">
                  <Routes />
                </div>
              </div>
            </div>
          )}
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps, null)(App);
