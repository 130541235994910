import { SHOW_REGISTER, SHOW_TECHNICIANS, SHOW_BOOKINGS, SHOW_MEMBERS, CLOSE_REGMEMB } from "../../actions/types";

const INITIAL_STATE = {
  showRegister: false,
  showMembers: false,
  showTechnicians: false,
  showBookings: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_REGISTER:
      return { ...state, showRegister: true, showMembers: false };
    case SHOW_MEMBERS:
      return { ...state, showMembers: true, showRegister: false };
    case SHOW_TECHNICIANS:
      return { ...state, showTechnicians: true, showRegister: false, showMembers: false };
    case SHOW_BOOKINGS:
      return {...state, showBookings: true}
    case CLOSE_REGMEMB:
      return { ...state, showMembers: false, showRegister: false, showIncidents: false };
    default:
      return state;
  }
};
