import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileUpload,
  faImage,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BookingInstance } from "../../../../../config";
import {
  fetchPosts,
  editPost,
  deletePost,
  selectPost,
} from "../../../../../Redux/actions/Posts/fetchPostActions";
import {
  showModal,
  hideModal,
} from "../../../../../Redux/actions/UI/toggleModalAction";
import { Col, Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import placeHolderImage from "../../../../../assets/images/placeholderImage.png";
import ImageModal from "./ImageModal";

class Posts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      content: "",
      imageUrl: "",
      originalImageUrl: "",
      image: null,
      showImageModal: false,
      error: {},
      showAlert: false,
      show: false,
      loading: false,
      file: "",

      titleError: false,
      contentError: false,
      mageError: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.location.prePopulate) {
      await this.setState({
        title: this.props.location.prePopulate.title,
        content: this.props.location.prePopulate.content,
        imageUrl: this.props.location.prePopulate.img_url,
        originalImageUrl: this.props.location.prePopulate.img_url,
        edit: true,
      });
    }
  };

  onCancelImg = () => {
    if (this.props.location.prePopulate) {
      this.setState({ imageUrl: this.state.originalImageUrl });      
    } else {
      this.setState({ image: null });
      this.setState({ imageUrl: "" });
    } 
  }

  removeImage = () => {
      this.setState({ image: null });
      this.setState({ imageUrl: "" });
      this.setState({ originalImageUrl: "N/A" });
  };

  onChangeUrl = (event) => {
    let url = event.target.value;
    this.setState({ imageUrl: url });
    document.getElementById("preview-image").src = url;
  };

  onChangeImage = (event) => {
    let image = event.target.files[0];
    this.setState({ image: image });

    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
      this.setState({ imageUrl: reader.result });
      this.setState({ originalImageUrl: reader.result });
    };
    reader.readAsDataURL(file);
  };

  onCancel = async () => {
    await this.props.history.push("/Dashboard");
  };

  showModal() {
    this.setState({ showImageModal: true });
  }

  handleChange = (event) => {
    this.setState({ filterText: event.target.value });
  };

  onChangeTitle = (event) => {
    this.setState({ title: event.target.value });
  };

  onChangeContent = (event) => {
    this.setState({ content: event.target.value });
  };

  checkIfRequiredInputEntered() {
    this.setState({ titleError: !this.state.title });
    this.setState({ contentError: !this.state.content });
  }

  onSubmit = async () => {
    this.checkIfRequiredInputEntered();

    try {
      if (this.props.location.prePopulate) {
        const patch = {
          title: this.state.title,
          content: this.state.content,
        };
        
        if (this.state.imageUrl && this.state.imageUrl !== "N/A") {
          patch["image_url"] = this.state.imageUrl;
        } else {
          patch["image_url"] = "N/A"
        };
        
        await BookingInstance.patch(`announcement/${this.props.location.prePopulate.id}/`, patch);
        this.props.history.push("/Dashboard");
      } else {
        const formData = new FormData();
        formData.append("title", this.state.title);
        formData.append("content", this.state.content);
        formData.append("expiration_date", this.state.expirationDate);
        formData.append("discount_percentage", this.state.discountPercentage);

        if (this.state.image) {
          formData.append("image", this.state.image);
        } else {
          formData.append("image_url", this.state.imageUrl);
        }

        await BookingInstance.post("announcement/", formData).then((res) => {
          if (res.status === 201) {
            this.setState({ showAlert: true });
          }
        });
        await this.props.history.push("/Dashboard");
      }
    } catch (error) {
      console.log("Announcement API error");
    }
  };

  getImagePreview = () => {
    if (this.state.imageUrl && this.state.imageUrl !== "N/A") {
      return (
        <div id="preview-img-container">
          <img id="preview-image" alt="preview" src={this.state.imageUrl} />
          <FontAwesomeIcon
            icon={faTimesCircle}
            onClick={this.removeImage}
            id="preview-img-close"
          />
        </div>
      );
    } else {
      return <img id="preview-image" alt="preview" src={placeHolderImage} />;
    }
  };

  render() {
    return (
      <div>
        <FontAwesomeIcon
          icon={faFileUpload}
          size="sm"
          className="fontawesome-sidemenu"
          id="fonticon-list-news-off"
        />
        <h5 className="header-lists-news-off">News & Offers</h5>
        <div className="white-background-offers">
          <row>
            <span className="list-title">
              {this.state.edit === true ? "EDIT POST" : "NEWS POST"}
            </span>
          </row>
          <div>
            <br />
            <Form controlId="form-offer">
              {this.state.showAlert === true ? (
                <div className="alert alert-success" role="alert">
                  Post successfully { this.props.location.prePopulate ? "edited" : "created" }
                </div>
              ) : null}

              <Form.Row>
                <Form.Group md="6" sm="12" as={Col} controlId="formGridTitle">
                  <Form.Label className="labels-offers">Title</Form.Label>
                  <Form.Control
                    onChange={this.onChangeTitle}
                    value={this.state.title}
                    type="text"
                    placeholder="Title here"
                  />
                  {this.state.titleError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Title is required
                    </div>
                  )}
                </Form.Group>
                <Form.Group md="6" sm="12" as={Col} controlId="formGridMedia">
                  <Form.Label className="labels-offers">
                    <FontAwesomeIcon
                      icon={faImage}
                      style={{ marginRight: "5px", color: "#202F81" }}
                      size="sm"
                    />
                    Add media
                  </Form.Label>
                  <div className="select-image-button-container">
                    <Button
                      size="sm"
                      style={{display: "inline-block"}}
                      className="select-image-button"
                      onClick={() => this.showModal()}
                    >
                      Select Image 
                    </Button>
                    {(!this.state.imageUrl || this.state.imageUrl == "N/A") && <p style={{display: "inline-block", marginBottom: 0, fontSize: 11}}>No file chosen</p>}
                  </div>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group md="6" sm="12" as={Col} controlId="formGridTextPost">
                  <Form.Label className="labels-offers">Main Text</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={9}
                    placeholder="Main text"
                    value={this.state.content}
                    onChange={this.onChangeContent}
                  />
                  {this.state.contentError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      News description is required.
                    </div>
                  )}
                </Form.Group>{" "}

                <Form.Group as={Col}>
                  <Form.Row className="imgPreviewPost col">
                    {this.getImagePreview()}
                  </Form.Row>
                </Form.Group>
              </Form.Row>
              <br />
              <br />
              <Form.Row>
                <Form.Group id="news-offers-btns" as={Col} controlId="formGridEmail">
                    <Button
                      bsPrefix="publish-offer-btn"
                      size="md"
                      style={{ marginRight: "20px" }}
                      onClick={this.onSubmit}
                    >
                      {this.state.edit === true ? "Edit News" : "Publish News"}
                    </Button>

                    <Button
                      bsPrefix="custom-btn-cancel"
                      size="md"
                      onClick={this.onCancel}
                    >
                      Cancel
                    </Button>
                </Form.Group>
              </Form.Row>
              <ImageModal
                show={this.state.showImageModal}
                onHide={() => this.setState({ showImageModal: false })}
                imageUrl={this.state.imageUrl}
                onChangeUrl={this.onChangeUrl}
                removeImage={this.onCancelImg}
                image={this.state.image}
                onChangeImage={this.onChangeImage}
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    posts: state.posts,
    modal: state.toggleModal.show,
    selectedPost: state.selectedPost,
    editPost: state.editPost,
    deletedPost: state.deletePost,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchPosts, editPost, selectPost, deletePost, showModal, hideModal },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Posts);
