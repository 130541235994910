/* eslint-disable import/no-anonymous-default-export */

import {
    FETCH_BOOKINGS_REGISTRATION_START,
    FETCH_BOOKINGS_REGISTRATION_SUCCESS,
    FETCH_BOOKINGS_REGISTRATION_FAILURE,
  } from "../../actions/types";
  
  export default (state = null, action) => {
    switch (action.type) {
      case FETCH_BOOKINGS_REGISTRATION_START:
        return {
          ...state,
          isLoading: true,
        };
      case FETCH_BOOKINGS_REGISTRATION_SUCCESS:
          console.log('17 action is', action)
        return {
          ...state,
          isLoading: false,
          bookings: action,
        };
      case FETCH_BOOKINGS_REGISTRATION_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
 