import {
  SHOW_REGISTER,
  SHOW_TECHNICIANS,
  SHOW_BOOKINGS,
  SHOW_MEMBERS,
  CLOSE_REGMEMB,
  CLEAR_CSS,
  REMOVE_CLEAR_CSS,
} from "../types";

export const showRegister = () => {
  return {
    type: SHOW_REGISTER,
  };
};

export const showMembers = () => {
  return {
    type: SHOW_MEMBERS,
  };
};

export const showTechnicians = () => {
  return {
    type: SHOW_TECHNICIANS,
  };
};

export const showBookings = () => {
  return {
    type: SHOW_BOOKINGS,
  };
};

export const closeRegisterMember = () => {
  return {
    type: CLOSE_REGMEMB,
  };
};

export const clearCss = () => {
  return {
    type: CLEAR_CSS,
  };
};

export const removeClearCss = () => {
  return {
    type: REMOVE_CLEAR_CSS,
  };
};
