import React, { Component } from 'react';
import { CustomerInstance, UploadInstance } from '../../../../../config';
import { Formik, Form } from 'formik';
import { 
  FormRow, 
  FormInput, 
  FormButtons,  
  FormWrapper, 
  TermsAndConditions  
} from '../../../../../components/UI/Form/Form'
import { Button } from "react-bootstrap";
import * as validation from '../../../../../components/UI/Form/formValidation';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends,} from "@fortawesome/free-solid-svg-icons";
import ErrorModal from "../../../../../components/UI/Modal/ErrorModal";
import { connect } from "react-redux";


class NewCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        firstName: '',
        lastName: '',
        username: '',
        phoneNumber: '',
        email: '',
        show: false,
        error: {},
        file: null,
        photo: '',
        customerId: '',
        document: '',
        showAlert: false,
        edit: false,
      },
      selectedPhoto: null,
    };
  }

  componentDidMount = () => {
    if (this.props.location.prePopulate) {
      this.setState({
        form: {
          firstName: this.props.location.prePopulate.user.first_name,
          lastName: this.props.location.prePopulate.user.last_name,
          username: this.props.location.prePopulate.user.username,
          phoneNumber: this.props.location.prePopulate.telephone_number,
          email: this.props.location.prePopulate.email_address,
          edit: true,
        }
      });
      this.setState({ selectedPhoto: this.props.location.prePopulate.company_logo })
      console.log(this.state, this.props)
    }
  }

  openFile = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = event => {
      this.setState({ selectedPhoto: event.target.files[0] })
    }
    input.click();
  }

  onPhotoChange = async (event) => {
    this.setState({ selectedPhoto: event.target.files[0] })
  }

  setFormValues = (customer) => {
    this.setState({ username: customer.username })
    this.setState({ firstName: customer.first_name })
    this.setState({ lastName: customer.last_name })
    this.setState({ email: customer.email_address })
    this.setState({ phoneNumber: customer.telephone_number })
  }

  updateCompanyLogo = (response) => {
    const photoData = new FormData()
    photoData.append('company_logo', this.state.selectedPhoto)
    try {
      if (this.props.location.prePopulate) { 
        CustomerInstance.patch(`customer/${response.data.data.id}/logo/`, photoData)
      } else {
        CustomerInstance.post(`customer/${response.data.data.id}/logo/`, photoData)
      }
    } catch (error) {
      this.setState({ error: error, show: true });
    }
  }

  onSubmit = async (formValues) => {
    this.state.form = formValues;

    const customer = {
      username : this.state.form.username,
      first_name: this.state.form.firstName,
      last_name: this.state.form.lastName,
      email_address : this.state.form.email,
      telephone_number: this.state.form.phoneNumber,
    }

    if (this.props.location.prePopulate) {
      try {
        CustomerInstance.patch(`customer/${this.props.location.prePopulate.id}/`, customer).then( response => {
          this.updateCompanyLogo(response);
        })
        this.setState({ showAlert: true });
        this.setFormValues(customer);
      } catch (error) {
        console.log('Patch Customer API error')
      }
    } else {
      try {
        await CustomerInstance.post(`customer/`, customer).then(response => {
          this.updateCompanyLogo(response);
          if (response.status === 201) {
            this.setState({ showAlert: true });
            this.setFormValues(customer);
          }
        });
      } catch (error) {
        this.setState({ show: true });
      }
    }
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    let form = (
      <Formik
        enableReinitialize={true}
        initialValues={{...this.state.form}}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(formValues) => this.onSubmit(formValues)}>
        {({ values }) => ( 
          <Form className='custom-form' id="custom-form">
          <FormRow><span className="header-form"> {this.state.form.edit === true ? "EDIT CUSTOMER" : "NEW CUSTOMER"}</span></FormRow>
            <FormRow >
              <FormInput name='username'   size="col-sm-6 col-md-3 col-lg-4" validate={(value) => validation.inputIsRequired('Username', value)} />
              <FormInput name='firstName'  size="col-sm-6 col-md-3 col-lg-4" validate={(value) => validation.inputIsRequired('Name', value)} />
              <FormInput name='lastName'   size="col-sm-6 col-md-3 col-lg-4" validate={(value) => validation.inputIsRequired('Last name', value)} />
              <FormInput name='email'      size="col-sm-6 col-md-3 col-lg-4" validate={(value) => validation.email(value)} />
              <FormInput size="col-sm-6 col-md-3 col-lg-4" name='phoneNumber' validate={(value)=>validation.phoneNumber(value)}/>
              <div className="form-group col-sm-6 col-md-3 col-lg-4">
              <label htmlFor="logo">Company Logo</label>
              <div className="select-image-button-container">
                <Button
                  size="sm"
                  className="select-image-button"
                  style={{display: "inline-block"}}
                  onClick={() => this.openFile()}>
                    Select Image
                </Button>
                {!this.state.selectedPhoto && !this.state.form.photo && <p style={{display: "inline-block", marginBottom: 0, fontSize: 11}}>No file chosen</p>}
              </div>
              </div>
            </FormRow>
            <br />
            <FormRow >
              <div className="row-terms">
              <TermsAndConditions
                size="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                name="theCustomerHasReadAndAcceptsThePrivacyPolicyAndTheTermsAndConditions"
                validate={(value) =>
                  validation.inputIsRequired("Terms And Conditions", value, "The customer must agree to the terms and conditions.")
                }
              />
              </div>
            </FormRow>
            <FormRow>
              {this.state.form.edit === true 
                ? <FormButtons btn="Edit Customer" text="* All fields are required." /> 
                : <FormButtons btn="Register Customer" text="* All fields are required." />
              }
            </FormRow>
            <ErrorModal
              onHide={this.handleClose}
              handleClose={this.handleClose}
              show={this.state.show}
              error={this.state.error}
            />
          </Form>
        )}
      </Formik>
    );

    return (
      <div>
        <FontAwesomeIcon icon={faUserFriends} size="sm" className="fontawesome-sidemenu" id="fonticon-list"/>
        <h5 className="header-lists">Customers</h5>
        <div className="register-client-form">
        { 
          this.state.showAlert === true ? <div className="alert alert-success" role="alert">
            Customer successfully  { this.props.location.prePopulate ? "edited" : "created" }
          </div> 
          : null 
        } 
        <FormWrapper>  {form}  </FormWrapper>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
  customers: state.customers,
});

export default connect(mapStateToProps,null)(NewCustomer);
