import {
  FETCH_CUSTOMERS_REGISTRATION_START,
  FETCH_CUSTOMERS_REGISTRATION_SUCCESS,
  FETCH_CUSTOMERS_REGISTRATION_FAILURE,
} from "../types";
import { CustomerInstance } from "../../../config";

const fetchCustomersStart = () => {
  return {
    type: FETCH_CUSTOMERS_REGISTRATION_START,
  };
};

const fetchCustomersSuccess = (customers) => {
  return {
    type: FETCH_CUSTOMERS_REGISTRATION_SUCCESS,
    customers,
  };
};

const fetchCustomersFailure = (error) => {
  return {
    type: FETCH_CUSTOMERS_REGISTRATION_FAILURE,
    error,
  };
};

const fetchCustomersRegistrationDetails = () => {
  return CustomerInstance.get("customer/registration/request/");
};

export const fetchCustomersRegistration = () => (dispatch) => {
  dispatch(fetchCustomersStart());
  return fetchCustomersRegistrationDetails().then(
    (response) => dispatch(fetchCustomersSuccess(response.data.data)),
    (error) => dispatch(fetchCustomersFailure(error))
  );
};
