import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchTechnicians,
  editTechnician,
  selectTechnician,
  deleteTechnician,
} from "../../../../../Redux/actions/Technicians/fetchTechnicianAction";
import { showModal, hideModal } from "../../../../../Redux/actions/UI/toggleModalAction";
import DataTable from "react-data-table-component";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import arrow from "../../../../../assets/arrow.svg";
import Edit from "../../../../../assets/edit.svg";
import Delete from "../../../../../assets/delete.svg";
import orderBy from "lodash/orderBy";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools} from "@fortawesome/free-solid-svg-icons";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { Link } from 'react-router-dom';

class TechnicianTable extends Component {
  constructor() {
    super();
    this.state = {
      filterText: "",
    };
  }

  componentDidMount() {
    this.props.fetchTechnicians();
    console.log(this.props)
  }

  handleChange = (event) => {
    this.setState({ filterText: event.target.value });
  };

  modalHandler() {
    if (this.props.modal) {
      return (
        <MDBContainer
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "0px 0px 0px #888888",
            padding: "2%",
          }}
        >
          <MDBModal isOpen={this.props.modal} toggle={this.props.modal} centered size="sm">
            <MDBModalHeader>Delete</MDBModalHeader>
            <MDBModalBody>Are you sure ?</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="light" onClick={this.props.hideModal} size="sm">
                Cancel
              </MDBBtn>
              <MDBBtn
                color="danger"
                onClick={() => {
                  if (this.selectTechnician !== null) {
                    this.props.deleteTechnician(this.props.selectedTechnician.id);
                    this.props.hideModal();
                  }
                }}
                size="sm"
              >
                Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
    }
  }

  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field]) {
        return row[field].toLowerCase();
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  actionOptions = (row) => {
    return (
      <>
        <Dropdown.Item onClick={() => this.props.editTechnician(row.id)}>
          <div className="table-edit">
            <img src={Edit} alt="" /> Edit
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            this.props.selectTechnician(row.id);
            this.props.showModal();
          }}
        >
          <div className="table-delete">
            <img src={Delete} alt="" /> Delete
          </div>
        </Dropdown.Item>
      </>
    );
  };

  renderUsers() {
    if (this.props.technicians !== null && this.props.technicians.isLoading === false) {
      return this.props.technicians.technicians.technicians.map((technician) => {
        return {
          id: technician.id,
          name: technician.first_name,
          lastName: technician.last_name,
          survey: technician.capable_for_survey ? 'Yes' : 'No' ,
          installation: technician.capable_for_installation ? 'Yes' : 'No' ,
          other: technician.other_capabilities,
          details: technician.details,
          edit: (
            <MDBBtn color="primary" size="sm" onClick={() => this.props.editTechnician(technician.id)}>
              Edit
            </MDBBtn>
          ),
          delete: (
            <MDBBtn
              color="danger"
              size="sm"
              onClick={() => {
                this.props.selectTechnician(technician.id);
                this.props.showModal();
                
              }}
            >
              Delete
            </MDBBtn>
          ),
        };
      });
    }
  }

  render() {
    let filteredData;
    const { filterText } = this.state;
    if (this.props.technicians !== null && this.props.technicians.isLoading === false) {
      filteredData = this.renderUsers().filter(
        (item) =>
          item.name.toLowerCase().includes(filterText.toLowerCase()) ||
          item.lastName.toLowerCase().includes(filterText.toLowerCase()) ||
          item.other.toLowerCase().includes(filterText.toLowerCase()) ||
          item.details.toLowerCase().includes(filterText.toLowerCase()) ||
          item.survey.toLowerCase().includes(filterText.toLowerCase()) ||
          item.installation.toLowerCase().includes(filterText.toLowerCase()) 

      );
    }

    const columns = [
      {
        name: "Name",
        selector: "name",
        grow: 1,
        sortable: true,
      },
      {
        name: "Last Name",
        selector: "lastName",
        grow: 1,
        sortable: true,
      },
      {
        name: "Survey",
        selector: "survey",
        grow: 1,
        sortable: true,
      },
      {
        name: "Installation",
        selector: "installation",
        grow: 1,
        sortable: true,
      },
      {
        name: "Other",
        selector: "other",
        grow: 1,
        sortable: true,
      },
      {
        name: "Details",
        selector: "details",
        grow: 1,
        sortable: true,
      },
      {
        cell: (row) => (
          <Dropdown>
            <Dropdown.Toggle id="dropdown-action-user-org" style={{marginTop:"13px"}}>
              <img src={arrow} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="edit-table-wrapper">{this.actionOptions(row)}</Dropdown.Menu>
          </Dropdown>
        ),
        selector: "action",
        allowOverflow: true,
        button: true,
        right: true,
      },
    ];

    if (this.props.technicians !== null && this.props.technicians.isLoading === true) {
      return <Spinner />;
    } else {
      return (
        <div>
             <FontAwesomeIcon icon={faTools} size="sm" className="fontawesome-sidemenu" id="fonticon-list"/>
            <h5 className="header-lists">Technicians</h5>
          <div className="white-background-customers">
          <row>
            <span className="list-title">TECHNICIAN LIST</span>
              <Link to="/RegisterTechnician">
                <button type="button" data-testid="org-button" className="dashboard-register-button" id="register-button">
                   New Technician
                </button>                
              </Link>   
          </row>
          <div className="table-search-wrapper">
            <input
              className="table-search"
              placeholder="  Search Technicians"
              value={this.filter}
              onChange={this.handleChange}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination={true}
            paginationPerPage={4}
            paginationRowsPerPageOptions={[4, 10, 15]}
            sortFunction={this.customSort}
          />
          </div>
         
          <div>{this.modalHandler()}</div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    technicians : state.technicians,
    modal: state.toggleModal.show,
    selectedTechnician: state.selectedTechnician,
    deletedTechnician: state.deleteTechnician,
    editTechnician: state.editTechnician,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchTechnicians, selectTechnician, editTechnician, deleteTechnician, showModal , hideModal},
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TechnicianTable);


