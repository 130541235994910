import React, { Component } from "react";
import { TechInstance, GoogleAPIKey } from "../../../../../config";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";


class TechniciansMap extends Component {
  static defaultProps = {
    center: { lat: 53.36, lng: -6.26 },
    zoom: 2,
  };

  getLocationFromLatLng = async (lat, lng) => {
    Geocode.setApiKey(GoogleAPIKey);
    Geocode.enableDebug();
    return Geocode.fromLatLng(lat, lng).then(
      (response) => response.results[0].formatted_address,
      (error) => console.error(error)
    );  
  }

  setInfoWindow = (technician) => `
    <div style="margin: 10px;">
      <div style="font-size: 18px; font-weight: bold;">
        ${technician.first_name}
      </div>
      <div style="font-size: 14px; padding-top: 5px;">
        <b>Details:</b> ${technician.details}
      </div>
      <div style="font-size: 14px; padding-top: 5px;">
        <b>Location:</b> ${technician.location}
      </div>
      <div style="font-size: 14px; padding-top: 5px;">
        <b>Capable of installation?</b> ${technician.capable_for_installation}
      </div>
      <div style="font-size: 14px; padding-top: 5px;">
      <b>Capable of survey?</b> ${technician.capable_for_survey}
    </div>
    </div>`;

    async getTechnicianList() {
      try {
        return await (await TechInstance.get(`/technician/`)).data;
      } catch (err) {
        // throw Error("Get  List API error");
        console.log(err)
      }
    }
    renderMarkers = async (map, maps) => {
      const incident_list = await this.getTechnicianList();
      if ( incident_list !== undefined){    
        for await (let tech of incident_list.data) {
        tech['location'] = await this.getLocationFromLatLng(tech.lat, tech.lng)
      }

      let infowindows = [];
      let markers = [];
      if (incident_list !== undefined) {
      incident_list.data.forEach((technician) => {
        markers.push(
          new maps.Marker({
            position: {
              lat: Number(technician.lat),
              lng: Number(technician.lng),
            },
            map,
          })
        );
        infowindows.push(
          new maps.InfoWindow({
            content: this.setInfoWindow(technician),
          })
        );
      });
      markers.forEach((marker, i) => {
        marker.addListener("mouseover", () => {
          infowindows[i].open(map, marker);
        });
        marker.addListener("mouseout", () => {
          infowindows[i].close(map, marker);
        });
      });
    }
  }
    };

  render() {
    return (
      <React.Fragment>
        <div className="map">
        <div className="map-dashboard">
          <GoogleMapReact
            bootstrapURLKeys={{ key: GoogleAPIKey }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
          />
        </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TechniciansMap;
