import {
  FETCH_TECHNICIANS_START,
  FETCH_TECHNICIANS_SUCCESS,
  FETCH_TECHNICIANS_FAILURE,
} from "../../actions/types";

export default (state = null, action) => {
  switch (action.type) {
    case FETCH_TECHNICIANS_START:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_TECHNICIANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        technicians: action,
      };
    case FETCH_TECHNICIANS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
