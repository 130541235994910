import { AdminInstance } from '../../../config';

const authorizationHeader = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("jwtToken"),
  },
};

export const inputIsRequired = (subject, value, errorMessage) => {
  if (!value) {
    if (!errorMessage) {
      return `${subject} is required.`
    } else {
      return errorMessage
    }
  }
}

export const email = async (value, prePopulateEmail) => {
  if (!value) {
    return 'Email is required.'
  }
  if (!/^.+@.+\..+$/.test(value)) {
    return 'Please enter valid email.'
  }

  // try {
  //   const users = await AdminInstance.get('user', authorizationHeader);
  //   for (const user of users.data.data) {
  //     if ((user.email === value) && (prePopulateEmail !== value)) {
  //       return `This email, ${value}, has been taken.`
  //     }
  //   }
  // } catch {
  //   console.log('Get Users API Error')
  // }
}

export const phoneNumber = (value) => {
  if (!value) {
    return 'Phone number is required.'
  }
  if (!/^((00|\+)\d{2})?\d{10}$/.test(value)) {  // Same regex is used on the backend for validation.
    return 'Please enter a valid phone number.'
  }
}

export const selectionIsRequired = (subject, value, errorMessage) => {
  if ((value !== false && !value) || value === 'NONE') {
    if (!errorMessage) {
      return `Please choose ${subject}.`;
    } else {
      return errorMessage
    }
  }
}

export const termsAndConditionsTechnicianForm = (value, values) => {
  // Terms and Conditions checkbox shows error when a different checkbox gets selected. 
  // A more general solution would be better, but I can't find it,
  // so this sello-tape solution is used for now.

  const formComplete = 
    values.firstName
    && values.lastName
    && values.email  
    && values.phoneNumber  
    && values.otherCapabilities  
    && values.details  
  
  if (formComplete && !value) {
    return "The customer must agree to the terms and conditions."
  }
}

export const organizationIdField = (value, values) => {
  if (values && values.length === 0) {
    return 'Currently, there are no organizations of this type. Please choose a different Organization Type.'
  }
  if (value === 'NONE') {
    return `Please choose Organization.`;
  }
}

export const username = async (value, min, max, prePopulateUsername) => {
  if (!value) {
    return `Username is required.`;
  }
  if (value.length < min) {
    return `Username length should be greater than ${min} characters.`
  }
  if (value.length > max) {
    return `Username length should be less than ${min} characters.`
  }
  try {
    const users = await AdminInstance.get('user', authorizationHeader);
    for (const user of users.data.data) {
      if ((user.username) === value && (prePopulateUsername !== value)) {
        return `Username, ${value}, has been taken.`
      }
    }
  } catch {
    console.log('Get Users API Error')
  }
}

export const maxNum = (subject, value, max) => {
  if (!value && value !==0) {
    return `${subject} is required.`
  }
  if (isNaN(value)) {
    return 'A valid number is required.'
  } 
  if (value < 0) {
    return `${subject} should be equal to or greater than 0.`
  } else if (value > max) {
    return `${subject} should be less than ${max}.`
  }
}


export const minMax = (subject, value, min, max) => {
  if (!value) {
    return `${subject} is required.`
  }
  if (value.length < min) {
    return `${subject} should be greater than ${min}.`
  } else if (value.length > max) {
    return `${subject} should be less than ${max}.`
  }
}

// export const CheckPassword = (password, confirmPassword) => {

//     if($confirmPassword.val() != "" && $password.val != ""){
//         if( $confirmPassword.val() != $password.val() ){
//             $submitBtn.attr("disabled", "disabled");
//             $errorMsg.insertAfter($confirmBox);
//         }
//     }
//   }


// export const checkPassword = (subject, value, errorMessage) => 
//  { 
//   // If password not entered 
//   if (this.props.password === '') 
//   return errorMessage 
        
//   // If confirm password not entered 
//   else if (this.props.password2 === '') 
//       alert ("Please enter confirm password"); 
      
//   // If Not same return False.     
//   else if (this.props.password !== this.props.password2) { 
//       alert ("\nPassword did not match: Please try again...") 
//       return false; 
//   } 

//   // If same return True. 
//   else{ 
//       alert("Password Match: Welcome to GeeksforGeeks!") 
//       return true; 
//   }

// }