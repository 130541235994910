import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import SecuredRoute from "../src/securityUtils/SecureRoute";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";

import Login from "./containers/Authentication/Login";
import Logout from "./containers/Authentication/Logout";

// Admin Routes
import AdminDashboard from "./containers/Screens/Admin/Dashboard";
import IncidentsMap from "./containers/Screens/Admin/ResourceMGT/Technicians/IncidentsMap";
import Bookings from "./containers/Screens/Admin/ResourceMGT/Notifications/Bookings";
import BookingsMGT from "./containers/Screens/Admin/ResourceMGT/Notifications/BookingsMGT";
import NewCustomer from "./containers/Screens/Admin/ResourceMGT/Customers/NewCustomer";
import CustomerList from "./containers/Screens/Admin/ResourceMGT/Customers/CustomerList"
import NewAdmin from "./containers/Screens/Admin/ResourceMGT/Admins/NewAdmin";
import AdminList from "./containers/Screens/Admin/ResourceMGT/Admins/AdminList";

// Guest Routes
// eslint-disable-next-line

//PSAP Routes
import registerTechnician from "./containers/Screens/Admin/ResourceMGT/Technicians/TechnicianForm";


//New Incident Routes
import TechnicianTable from "./containers/Screens/Admin/ResourceMGT/Technicians/TechnicianTable";
import ListOffersNews from "./containers/Screens/Admin/ResourceMGT/OffersNews/ListOffersNews";
import Offers from "./containers/Screens/Admin/ResourceMGT/OffersNews/Offers";
import Posts from "./containers/Screens/Admin/ResourceMGT/OffersNews/Posts"

class Routes extends Component {
  render() {
    // Expose the paths that correspond to each user role
    let exposedUrls;
    if (this.props.security.validToken) {
      const token = localStorage.getItem("jwtToken");
      const decoded = jwt_decode(token);
      // let role = decoded.user_roles[0];

      // switch (role) {
        // Admin private routes
        // case "HIGH_COMMANDER":
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path="/" component={AdminDashboard} />
              <SecuredRoute exact path="/Dashboard" component={AdminDashboard} />
              <SecuredRoute exact path="/IncidentsMap" component={IncidentsMap} />
              <SecuredRoute exact path="/Logout" component={Logout} />
             <SecuredRoute exact path="/RegisterTechnician" component={registerTechnician} />
              <SecuredRoute exact path="/IncidentInfo" component={TechnicianTable} />
              <SecuredRoute exact path="/TechnicianMGT" component={TechnicianTable} />
              <SecuredRoute exact path="/Bookings" component={Bookings} />
              <SecuredRoute exact path="/BookingsMGT" component={BookingsMGT} />
              <SecuredRoute exact path="/NewCustomer" component={NewCustomer} />
              <SecuredRoute exact path="/CustomerList" component={CustomerList} />
              <SecuredRoute exact path="/NewAdmin" component={NewAdmin} />
              <SecuredRoute exact path="/AdminList" component={AdminList} />
              <SecuredRoute exact path="/NewOffer" component={Offers} />
              <SecuredRoute exact path="/NewPost" component={Posts} />
              <SecuredRoute exact path="/Offers-News" component={ListOffersNews} />
            </Switch>
          );
          // break;
        // default:
          // break;
      // }
    } else {
      exposedUrls = <Route component={Login} />;
    }
    return (
      <div>
        <Switch>
          {
            // Public Routes
          }
          {/* <Route exact path="/Login" component={Login} /> */}
          {exposedUrls}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps, null)(Routes);
