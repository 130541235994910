import {
    FETCH_POSTS_START,
    FETCH_POSTS_SUCCESS,
    FETCH_POSTS_FAILURE,
    EDIT_POST,
    POST_SELECTED,
    DELETE_POST,
  } from "../types";
  import history from "../../../history";
  import { PostInstance} from "../../../config";
  
  const fetchPostsStart = () => {
    return {
      type: FETCH_POSTS_START,
    };
  };
  
  const fetchPostsSuccess = (posts) => {
    return {
      type: FETCH_POSTS_SUCCESS,
      posts,
    };
  };
  
  const fetchPostsFailure = (error) => {
    return {
      type: FETCH_POSTS_FAILURE,
      error,
    };
  };
  
  const fetchPostDetails = () => {
    const response = PostInstance.get("announcement/");
    return response;
  };
  
  export const fetchPosts = () => (dispatch) => {
    dispatch(fetchPostsStart());
    return fetchPostDetails().then(
      (response) => dispatch(fetchPostsSuccess(response.data.data)),
      (error) => dispatch(fetchPostsFailure(error))
    );
  };
  
  export const selectPost = (id) => {
    return {
      type: POST_SELECTED,
      id: id,
    };
  };
  
  export const deletePost = (id) => {
    return async (dispatch) => {
      const response = await PostInstance.delete(`announcement/${id}/`);
      dispatch({ type: DELETE_POST, payload: response.data });
      history.push("/");
    };
  };
  
  export const editPost = (id) => {
    return async (dispatch) => {
      const response = await PostInstance.get(`announcement/${id}/`);
      dispatch({ type: EDIT_POST, payload: response.data});
      history.push({
        pathname: "/NewPost",
        prePopulate: response.data.data,
        populate: true,
      });
    };
  };
  