import axios from "axios";

export let authInstance

if (process.env.REACT_APP_ENV === 'production') {
  authInstance = axios.create({
    baseURL: "https://back.marvelmarine.gr/api/",
  });
  authInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("jwtToken");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });
} else {
 authInstance = axios.create({
    baseURL: "http://167.71.60.27:2021/api/",
  });
  authInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("jwtToken");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

}



export let UserInstance
if (process.env.REACT_APP_ENV === 'production') {
  UserInstance = axios.create({
    baseURL: "https://back.marvelmarine.gr/api/users/",
  });
  UserInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("jwtToken");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });
} else {
  UserInstance = axios.create({
    baseURL: "http://167.71.60.27:2021/api/users/",
  });
  UserInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("jwtToken");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

}

 export let AdminInstance

if (process.env.REACT_APP_ENV === 'production') {
  AdminInstance = axios.create({
    baseURL: "https://back.marvelmarine.gr/api/users/",
  });
  AdminInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("jwtToken");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });
} else {
 
  AdminInstance = axios.create({
    baseURL: "http://167.71.60.27:2021/api/users/",
  });
  AdminInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("jwtToken");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });
}
 
  export let CustomerInstance
  if (process.env.REACT_APP_ENV === 'production') {
    CustomerInstance = axios.create({
      baseURL: "https://back.marvelmarine.gr/api/users/",
    });
    CustomerInstance.interceptors.request.use(function (config) {
      const token = localStorage.getItem("jwtToken");
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;
      
    });
  } else {
    CustomerInstance = axios.create({
      baseURL: "http://167.71.60.27:2021/api/users/",
    });
    CustomerInstance.interceptors.request.use(function (config) {
      const token = localStorage.getItem("jwtToken");
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;
      
    });
  
  }
  

   export let UploadInstance
   if (process.env.REACT_APP_ENV === 'production') {
    UploadInstance = axios.create({
      baseURL: "https://back.marvelmarine.gr/api/booking/",
    });
    UploadInstance.interceptors.request.use(function (config) {
      const token = localStorage.getItem("jwtToken");
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config; 
    });
  } else {
    UploadInstance = axios.create({
      baseURL: "http://167.71.60.27:2021/api/booking/",
    });
    UploadInstance.interceptors.request.use(function (config) {
      const token = localStorage.getItem("jwtToken");
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;     
    });
  
  }
  


    export let TechInstance
    if (process.env.REACT_APP_ENV === 'production') {
      TechInstance = axios.create({
        baseURL: "https://back.marvelmarine.gr/api/booking/",
      });
      TechInstance.interceptors.request.use(function (config) {
        const token = localStorage.getItem("jwtToken");
        config.headers.Authorization = token ? `Bearer ${token}` : "";
        return config;
      });
    } else {
      TechInstance = axios.create({
        baseURL: "http://167.71.60.27:2021/api/booking/",
      });
      TechInstance.interceptors.request.use(function (config) {
        const token = localStorage.getItem("jwtToken");
        config.headers.Authorization = token ? `Bearer ${token}` : "";
        return config;
      });
    
    }
    

     export let BookingInstance
     if (process.env.REACT_APP_ENV === 'production'){
     
      BookingInstance = axios.create({
        baseURL: "https://back.marvelmarine.gr/api/booking/",
      });
      BookingInstance.interceptors.request.use(function (config) {
        const token = localStorage.getItem("jwtToken");
        config.headers.Authorization = token ? `Bearer ${token}` : "";
        return config;
      });
      } else {
        BookingInstance = axios.create({
          baseURL: "http://167.71.60.27:2021/api/booking/",
        });
        BookingInstance.interceptors.request.use(function (config) {
          const token = localStorage.getItem("jwtToken");
          config.headers.Authorization = token ? `Bearer ${token}` : "";
          return config;
        });
      }
      

     export let OfferInstance
     if (process.env.REACT_APP_ENV === 'production'){
     
      OfferInstance = axios.create({
        baseURL: "https://back.marvelmarine.gr/api/booking/",
      });
      OfferInstance.interceptors.request.use(function (config) {
        const token = localStorage.getItem("jwtToken");
        config.headers.Authorization = token ? `Bearer ${token}` : "";
        return config;
      });
      } else {
        OfferInstance = axios.create({
          baseURL: "http://167.71.60.27:2021/api/booking/",
        });
        OfferInstance.interceptors.request.use(function (config) {
          const token = localStorage.getItem("jwtToken");
          config.headers.Authorization = token ? `Bearer ${token}` : "";
          return config;
        });
      } 

      export let PostInstance
      if (process.env.REACT_APP_ENV === 'production'){
      
       PostInstance = axios.create({
         baseURL: "https://back.marvelmarine.gr/api/booking/",
       });
       PostInstance.interceptors.request.use(function (config) {
         const token = localStorage.getItem("jwtToken");
         config.headers.Authorization = token ? `Bearer ${token}` : "";
         return config;
       });
       } else {
         PostInstance = axios.create({
           baseURL: "http://167.71.60.27:2021/api/booking/",
         });
         PostInstance.interceptors.request.use(function (config) {
           const token = localStorage.getItem("jwtToken");
           config.headers.Authorization = token ? `Bearer ${token}` : "";
           return config;
         });
       } 
 


export const AnalAPIKey = "6f249b9e850b8a513643f33ad75ba37b";

export const GoogleAPIKey = "AIzaSyDyblmvy9IiY7HBSdZ6e45gJMIf3I85cwQ";

export const SearchBoxId = "tech-form-map"

// // // Locali
// export const authInstance = axios.create({
//   baseURL: "http://192.168.2.19:9100/api/v1"
// });

// export const OrgInstance = axios.create({
//   baseURL: "http://192.168.2.19:8200/api/v1"
// });

// export const InciInstance = axios.create({
//   baseURL: "http://192.168.2.19:8300/api/v1"
// });
