import {
    FETCH_ADMINS_START,
    FETCH_ADMINS_SUCCESS,
    FETCH_ADMINS_FAILURE,
    EDIT_ADMIN,
    ADMIN_SELECTED,
    DELETE_ADMIN,
  } from "../types";
  import history from "../../../history";
  import { AdminInstance } from "../../../config";
  
  const fetchAdminsStart = () => {
    return {
      type: FETCH_ADMINS_START,
    };
  };
  
  const fetchAdminsSuccess = (admins) => {
    return {
      type: FETCH_ADMINS_SUCCESS,
      admins,
    };
  };
  
  const fetchAdminsFailure = (error) => {
    return {
      type: FETCH_ADMINS_FAILURE,
      error,
    };
  };
  
  const fetchAdminDetails = () => {
    const response = AdminInstance.get("administrator/");
    return response;
  };
  
  export const fetchAdmins= () => (dispatch) => {
    dispatch(fetchAdminsStart());
    return fetchAdminDetails().then(
      (response) => dispatch(fetchAdminsSuccess(response.data.data)),
      (error) => dispatch(fetchAdminsFailure(error))
    );
  };
  
  export const selectAdmin = (id) => {
    return {
      type: ADMIN_SELECTED,
      id: id,
    };
  };
  
  export const deleteAdmin = (id) => {
    return async (dispatch) => {
      const response = await AdminInstance.delete(`administrator/${id}/`);
      dispatch({ type: DELETE_ADMIN, payload: response.data });
      history.push("/");
    };
  };
  
  export const editAdmin= (id) => {
    return async (dispatch) => {
      const response = await AdminInstance.get(`administrator/${id}/`);
      dispatch({ type: EDIT_ADMIN, payload: response.data });
      history.push({
        pathname: "/NewAdmin",
        prePopulate: response.data.data,
        populate: true,
      });
    };
  };
  