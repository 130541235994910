import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select'
import {BookingInstance} from '../../../../../config'
 

class BookingStatusModal extends Component {
    constructor() {
        super();
        this.state = {
          bookingModalShow: false,
          status: ''
        };
    }

    handleClose=()=>{
        this.setState({bookingModalShow:false})
    }
    
    onChange=(value)=>{
        this.setState({status: value})
    }
    onSubmit = async()=>{
        let bookingStatus = {
            'booking_status' : this.state.status.value
        }
        try {
            BookingInstance.patch(`booking/${this.props.bookingId}/`, bookingStatus).then(response => {window.location.reload();})
        } catch (error) {
            console.log('Status not Changed',error)
        }
        this.setState({bookingModalShow: false})
        this.props.onHide();
    }

    render() {
        const options =[
            {value: 'TECHNICIAN_NOTIFIED', label: 'Technician Notified'},
            {value: 'TECHNICIAN_IN_TRANSIT', label: 'Technician In Transit'},
            {value: 'JOB_STARTED', label: 'Job Started'},
            {value: 'JOB_COMPLETE', label: 'Job Complete'},
        ]
        return (
            <div>
                <>
                    <Modal show={this.props.show} onHide={this.props.onHide} style={{marginTop: "100px"}}>
                        <Modal.Header closeButton>
                        <Modal.Title>Update Booking Status</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <Select 
                            options={options}
                            onChange={this.onChange}
                            value={this.state.status}
                        />
                        </Modal.Body>
                        <Modal.Footer>
                        <Button bsPrefix="btn outline-button" variant="light"  onClick={this.props.onHide}>
                            Cancel
                        </Button>
                        <Button variant="primary" bsPrefix="btn solid-button" onClick={this.onSubmit}>
                            Update Status
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            </div>
        );
    }
}

export default BookingStatusModal;