import React, { Component } from 'react';
import { AdminInstance } from '../../../../../config';
import { Formik, Form } from 'formik';
import { FormRow, FormInput, FormButtons, FormWrapper, TermsAndConditions } from '../../../../../components/UI/Form/Form'
import * as validation from '../../../../../components/UI/Form/formValidation';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faIdCardAlt} from "@fortawesome/free-solid-svg-icons";
import ErrorModal from "../../../../../components/UI/Modal/ErrorModal";
import { connect } from "react-redux";


class NewAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      firstName: '',
      lastName: '',
      email: '',

      termsAndConditions:false,
      showAlert: false,
      show: false,
      edit: false,
      error: {},
    };
  }

  componentDidMount = () => {
    if (this.props.location.prePopulate) {
      this.setState({
        firstName: this.props.location.prePopulate.user.first_name,
        lastName: this.props.location.prePopulate.user.last_name,
        username: this.props.location.prePopulate.user.username,
        email: this.props.location.prePopulate.user.email,
        edit: true,
      })
    }
  }

  setFormValues = (admin) => {
    this.setState({ username: admin.username })
    this.setState({ firstName: admin.first_name })
    this.setState({ lastName: admin.last_name })
    this.setState({ email: admin.email })
  }

  onSubmit = async (formValues) => {
    this.state = formValues;

    const admin = {
      username: this.state.username,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
    }

    try {
      if (this.props.location.prePopulate) {
        await AdminInstance.patch(`administrator/${this.props.location.prePopulate.id}/`, admin);
        this.setFormValues(admin);
        this.setState({ showAlert: true })
      } else {
        await AdminInstance.post('administrator/', admin).then(res => { 
          if (res.status === 201) { 
            this.setFormValues(admin);
            this.setState({ showAlert: true })
          }
        });
      }
    }
    catch (error) {
      console.log('Register Admin API error');
      this.setState({ show: true });
    }
  }
  
  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    let form = (
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        initialValues={{...this.state}}
        onSubmit={(formValues) => this.onSubmit(formValues)}>
        {({ values }) => (  
          <Form className='custom-form'  id="custom-form">
             <FormRow><span className="header-form">{this.state.edit === true ? "EDIT ADMIN" : "NEW ADMIN"}</span></FormRow>
             { this.state.showAlert === true ? 
                <div className="alert alert-success" role="alert">
                Admin successfully  { this.props.location.prePopulate ? "edited" : "created"}
                </div> 
               : null }             
            <FormRow>
              <FormInput name='username'  validate={(value) => validation.inputIsRequired('Username', value)} />
              <FormInput name='firstName' validate={(value) => validation.inputIsRequired('Name', value)} />
              <FormInput name='lastName'  validate={(value) => validation.inputIsRequired('Last name', value)} />
              <FormInput name='email' 
                validate={(value) => validation.email(value,
                  this.props.location.prePopulate  ? this.props.location.prePopulate.email : null)
                }
              />
            </FormRow>
            <br />
            <FormRow>
            <div className="row-terms">
              <TermsAndConditions
                size="col-12 col-sm-12 col-md-12 col-lg-12"
                name="theAdminHasReadAndAcceptsThePrivacyPolicyAndTheTermsAndConditions"
                validate={(value) =>
                  validation.inputIsRequired("Terms And Conditions", value, "Admin must agree to the terms and conditions.")
                }
              />
            </div>
            </FormRow>
            <FormRow>
            { this.state.edit === true 
              ? <FormButtons btn="Edit Admin" text="* All fields are required." /> 
              : <FormButtons btn="Register Admin" text="* All fields are required." />
            }
            </FormRow>
            <ErrorModal
              onHide={this.handleClose}
              handleClose={this.handleClose}
              show={this.state.show}
              error={this.state.error} />
          </Form>
        )}
      </Formik>
    );

    return (
      <div>
        <FontAwesomeIcon icon={faIdCardAlt} size="sm" className="fontawesome-sidemenu" id="fonticon-list" />
        <h5 className="header-lists">Admins</h5>
      <div className="register-client-form">
        <FormWrapper>
          {form}
        </FormWrapper>
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
  admins: state.admins,
});

export default connect(mapStateToProps,null)(NewAdmin);
