
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchOffers,
  editOffer,
  selectOffer,
  deleteOffer,
} from "../../../../../Redux/actions/Offers/fetchOfferActions";
import { showModal, hideModal } from "../../../../../Redux/actions/UI/toggleModalAction";
import DataTable from "react-data-table-component";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import arrow from "../../../../../assets/arrow.svg";
import Edit from "../../../../../assets/edit.svg";
import Delete from "../../../../../assets/delete.svg";
import orderBy from "lodash/orderBy";
import Dropdown from "react-bootstrap/Dropdown";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { Link } from 'react-router-dom';


export class OffersList extends Component {
  constructor() {
    super();
    this.state = {
      filterText: "",
    };
  }

  async componentDidMount() {
    await this.props.fetchOffers();
    console.log(this.props.fetchOffers())
  }

  handleChange = (event) => {
    this.setState({ filterText: event.target.value });
  };

  modalHandler() {
    if (this.props.modal) {
      return (
        <MDBContainer
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "0px 0px 0px #888888",
            padding: "2%",
          }}
        >
          <MDBModal isOpen={this.props.modal} toggle={this.props.modal} centered size="sm">
            <MDBModalHeader>Delete</MDBModalHeader>
            <MDBModalBody>Are you sure ?</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="light" onClick={this.props.hideModal} size="sm">
                Cancel
              </MDBBtn>
              <MDBBtn
                color="danger"
                onClick={() => {
                  if (this.selectedOffer !== null) {
                    this.props.deleteOffer(this.props.selectedOffer.id);
                    this.props.hideModal();
                  }
                }}
                size="sm"
              >
                Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
    }
  }

  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field]) {
        return row[field].toLowerCase();
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  actionOptions = (row) => {
    return (
      <>
        <Dropdown.Item onClick={() => this.props.editOffer(row.id)}>
          <div className="table-edit">
            <img src={Edit} alt="" /> Edit
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            this.props.selectOffer(row.id);
            this.props.showModal();
          }}
        >
          <div className="table-delete">
            <img src={Delete} alt="" /> Delete
          </div>
        </Dropdown.Item>
      </>
    );
  };

  renderOffers() {
    if (this.props.offers !== null && this.props.offers.isLoading === false) {
      return this.props.offers.offers.offers.map((offer) => {
        return {
          id: offer.id,
          title: offer.title,
          discount: offer.discount_percentage,
          published: offer.created_date.split('T'),
          edit: (
            <MDBBtn color="primary" size="sm" onClick={() => this.props.editOffer(offer.id)}>
              Edit
            </MDBBtn>
          ),
          delete: (
            <MDBBtn
              color="danger"
              size="sm"
              onClick={() => {
                this.props.selectOffer(offer.id);
                this.props.showModal();
              }}
            >
              Delete
            </MDBBtn>
          ),
        };
      });
    }
  }

  


    render() {
        let filteredData;
        const { filterText } = this.state;
        if (this.props.offers !== null && this.props.offers.isLoading === false) {
          filteredData = this.renderOffers().filter(
          (item) =>
            item.title.toLowerCase().includes(filterText.toLowerCase()) ||
            item.discount.toLowerCase().includes(filterText.toLowerCase()) ||
            item.published.toLowerCase().includes(filterText.toLowerCase()) ||
            item.email.toLowerCase().includes(filterText.toLowerCase())
      );
    }

    const columns = [
      {
        name: "ID",
        selector: "id",
        grow: 1,
        sortable: true,
      },
      {
        name: "Title",
        selector: "title",
        grow: 1,
        sortable: true,
      },
      {
        name: "Discount",
        selector: "discount",
        grow: 1,
        sortable: true,
      },
      {
        name: "Published",
        selector: "published",
        grow: 1,
        sortable: true,
      },
      {
        cell: (row) => (
          <Dropdown>
            <Dropdown.Toggle id="dropdown-action-user-org" style={{marginTop:"13px"}}>
              <img src={arrow} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="edit-table-wrapper">{this.actionOptions(row)}</Dropdown.Menu>
          </Dropdown>
        ),
        selector: "action",
        allowOverflow: true,
        button: true,
        right: true,
      },
    ];

    if (this.props.offers !== null && this.props.offers.isLoading === true) {
      return <Spinner />;
    } else {
      return (
        <div >
          <div className="white-background-offers-list">
          <row>
          <span className="list-title">OFFERS LIST</span>
            <Link to="/NewOffer">
              <button type="button" data-testid="org-button" style={{marginLeft:"-60px"}}className="dashboard-register-button">
               New Offer
              </button>
            </Link>
          </row>
          <div className="table-search-wrapper">
            <input
              className="table-search"
              placeholder="   Search Offers"
              value={this.filter}
              onChange={this.handleChange}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination={true}
            paginationPerPage={4}
            paginationRowsPerPageOptions={[4, 10, 15]}
            sortFunction={this.customSort}
          />
          </div>
          
          <div>{this.modalHandler()}</div>
        </div>
      );
    }
}
}

const mapStateToProps = (state) => {
  return {
   modal: state.toggleModal.show,
   offers : state.offers,
   security: state.security,
   selectedOffer: state.selectedOffer,
   editOffer: state.editOffer,
   deletedOffer: state.deletedOffer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchOffers, editOffer, selectOffer, deleteOffer, showModal, hideModal },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OffersList);
