import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchBookings,
  editBooking,
  selectBooking,
  deleteBooking,
} from "../../../../../Redux/actions/Bookings/fetchBookingAction";
import { fetchBookingsRegistration } from "../../../../../Redux/actions/Bookings/fetchBookingsRegistration";
import { BookingInstance, TechInstance } from "../../../../../config";
import {
  showModal,
  hideModal,
} from "../../../../../Redux/actions/UI/toggleModalAction";
import DataTable from "react-data-table-component";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import arrow from "../../../../../assets/arrow.svg";
import Edit from "../../../../../assets/edit.svg";
import Delete from "../../../../../assets/delete.svg";
import Status from "../../../../../assets/status.svg";
import orderBy from "lodash/orderBy";
import Dropdown from "react-bootstrap/Dropdown";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Drawer from "@material-ui/core/Drawer";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import { Link } from "react-router-dom";
import BookingStatusModal from "./BookingStatusModal";
import { Formik, Form } from 'formik';
import { FormRow, FormInput } from '../../../../../components/UI/Form/Form'
import * as validation from '../../../../../components/UI/Form/formValidation';


class Bookings extends Component {
  constructor() {
    super();
    this.state = {
      filterText: "",
      bookingModalShow: false,
      bookingId: "",
      showBookingDrawer: false,
      activeBookingRequests: [],
      anchorEl: null,
      isBookingRequestModalOpen: false,
      isEditBookingRequestModalOpen: false,
      showEditBookingAlert: false,
      selectedBooking: null,
      availableBookings: [],
    };
  }

  async componentDidMount() {
    this.props.fetchBookings();
    const bookingReg = await (await this.props.fetchBookingsRegistration()).bookings
    this.setState({ activeBookingRequests: bookingReg })
    this.closeBookingDrawer();
    this.setAvailableBookings();
  }

  componentWillUnmount() {
    this.closeBookingDrawer();
  }

  handleChange = (event) => {
    this.setState({ filterText: event.target.value });
  };

  modalHandler() {
    if (this.props.modal) {
      return (
        <MDBContainer
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "0px 0px 0px #888888",
            padding: "2%",
          }}
        >
          <MDBModal
            isOpen={this.props.modal}
            toggle={this.props.modal}
            centered
            size="sm"
          >
            <MDBModalHeader>Delete</MDBModalHeader>
            <MDBModalBody>Are you sure ?</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="light" onClick={this.props.hideModal} size="sm">
                Cancel
              </MDBBtn>
              <MDBBtn
                color="danger"
                onClick={() => {
                  if (this.selectedBooking !== null) {
                    this.props.deleteBooking(this.props.selectedBooking.id);
                    this.props.hideModal();
                  }
                }}
                size="sm"
              >
                Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
    }
  }

  bookingRequestModalHandler() {
    if (this.state.isBookingRequestModalOpen) {
      return (
        <MDBContainer
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "0px 0px 0px #888888",
            padding: "2%",
          }}
        >
          <MDBModal
            isOpen={this.state.isBookingRequestModalOpen}
            toggle={this.state.isBookingRequestModalOpen}
            centered
            size="md"
          >
            <MDBModalHeader>More Details</MDBModalHeader>
            <MDBModalBody>
              <p className="customer-list-registration-details">
                <span>Booking Location: </span>{this.state.selectedBooking.location}
              </p>
              <p className="customer-list-registration-details">
                <span>Customer Email: </span>{this.state.selectedBooking.customer.email_address}
              </p>
              <p className="customer-list-registration-details">
                <span>Customer Telephone Num.: </span>{this.state.selectedBooking.customer.telephone_number}
              </p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="light"
                onClick={() => {
                  this.setState({ isBookingRequestModalOpen: false })
                }}
                size="sm"
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
    }
  }

  onEditRequestedBooking = async (booking) => {
    const payload = {
      location: booking.location,
      from_date: booking.from_date,
      to_date: booking.to_date,
      remarks: booking.remarks,
    }

    try {
      await BookingInstance.patch(`booking-request/${booking.id}/`, payload);
      this.setState({ showEditBookingAlert: true })
    }
    catch (error) {
      console.log('Patch Request Booking API error');
    }
  }

  editBookingRequestModalHandler() {
    if (this.state.isEditBookingRequestModalOpen) {
      return (
        <MDBContainer
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "0px 0px 0px #888888",
            padding: "2%",
          }}
        >
          <MDBModal
            isOpen={this.state.isEditBookingRequestModalOpen}
            toggle={this.state.isEditBookingRequestModalOpen}
            centered
            size="md"
          >
            <MDBModalHeader>Edit Booking Request</MDBModalHeader>
            <MDBModalBody>
              {this.state.showEditBookingAlert &&
                <div className="alert alert-success" role="alert">
                  Edit successful
                </div>
              }
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                initialValues={{ ...this.state.selectedBooking }}
                onSubmit={(formValues) => this.onEditRequestedBooking(formValues)}>
                {({ values }) => (
                  <Form className='custom-form' id="custom-form">
                    <FormRow>
                      <FormInput size="col-md-12" name='location' validate={(value) => validation.inputIsRequired('location', value)} />
                    </FormRow>
                    <FormRow>
                      <FormInput size="col-md-12" name='from_date' validate={(value) => validation.inputIsRequired('From Date', value)} />
                    </FormRow>
                    <FormRow>
                      <FormInput size="col-md-12" name='to_date' validate={(value) => validation.inputIsRequired('To Date', value)} />
                    </FormRow>
                    <FormRow>
                      <FormInput size="col-md-12" name='remarks' validate={(value) => validation.inputIsRequired('Remarks', value)} />
                    </FormRow>
                    <FormRow>
                      <div style={{ marginLeft: 'auto', marginRight: 'auto' }} className="customer-list-registration-button-container">
                        <Button
                          bsPrefix="custom-btn"
                          size="md"
                          onClick={() => this.onEditRequestedBooking(values)}
                        >
                          Edit
                        </Button>
                        <Button
                          bsPrefix="custom-btn-cancel"
                          size="md"
                          onClick={() => this.setState({ isEditBookingRequestModalOpen: false })}
                        >
                          Cancel
                        </Button>
                      </div>
                    </FormRow>
                  </Form>
                )}
              </Formik>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      );
    }
  }

  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field]) {
        return row[field].toLowerCase();
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  bookingStatus = (id) => {
    this.setState({
      bookingModalShow: true,
      bookingId: id,
    });
  };

  actionOptions = (row) => {
    return (
      <>
        <Dropdown.Item onClick={() => this.props.editBooking(row.id)}>
          <div className="table-edit">
            <img src={Edit} alt="" /> Edit
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            this.bookingStatus(row.id);
          }}
        >
          <div className="table-pdf">
            <img src={Status} alt="" />
            Update Status
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            this.props.selectBooking(row.id);
            this.props.showModal();
          }}
        >
          <div className="table-delete">
            <img src={Delete} alt="" /> Delete
          </div>
        </Dropdown.Item>
      </>
    );
  };

  renderUsers() {
    if (
      this.props.bookings !== null &&
      this.props.bookings.isLoading === false
    ) {
      return this.props.bookings.bookings.bookings.map((booking) => {
        return {
          id: booking.id,
          technician: booking.technician.last_name,
          customer: booking.customer.user.last_name,
          dateFrom: booking.from_date,
          dateTo: booking.to_date,
          bookingStatus:
            booking.booking_status.charAt(0).toUpperCase() +
            booking.booking_status.slice(1).replace(/_/g, " ").toLowerCase(),
          edit: (
            <MDBBtn
              color="primary"
              size="sm"
              onClick={() => this.props.editBooking(booking.id)}
            >
              Edit
            </MDBBtn>
          ),
          delete: (
            <MDBBtn
              color="danger"
              size="sm"
              onClick={() => {
                this.props.selectBooking(booking.id);
                this.props.showModal();
              }}
            >
              Delete
            </MDBBtn>
          ),
          status: (
            <MDBBtn color="danger" size="sm">
              Status
            </MDBBtn>
          ),
        };
      });
    }
  }

  handleClickPopover = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  };

  handleClosePopover = () => {
    this.setState({ anchorEl: null })
  };

  openRegistrationDrawer = () => {
    this.setState({ showBookingDrawer: true });
  };

  closeBookingDrawer = () => {
    this.setState({ showBookingDrawer: false });
    this.props.fetchBookingsRegistration();
  };

  onClickAccept = async (booking) => {
    try {
      await BookingInstance.get(`booking-request/${booking.id}/accept/`)
      const newBookingRegistration = this.state.activeBookingRequests.filter((b) => b.id !== booking.id);
      this.setState({ activeBookingRequests: newBookingRegistration })
      this.setAvailableBookings();
    } catch (error) {
      console.log("Activate booking API error!");
    }
  };

  onClickDecline = async (booking) => {
    try {
      await BookingInstance.patch(`booking-request/${booking.id}/`, { "denied": true })
      const newBookingRegistration = this.state.activeBookingRequests.filter((b) => b.id !== booking.id);
      this.setState({ activeBookingRequests: newBookingRegistration })
    } catch (error) {
      console.log("Delete booking API error!");
    }
  };

  openBookingRequestModal = (booking) => {
    this.handleClosePopover();
    this.setState({ isBookingRequestModalOpen: true });
    this.setState({ selectedBooking: booking });
  };

  openEditBookingRequestModal = (booking) => {
    this.handleClosePopover();
    this.setState({ isEditBookingRequestModalOpen: true });
    this.setState({ selectedBooking: booking });
  };

  getTimeDiff = (created) => {
    const diff = Date.now() - new Date(created);
    const mins = Math.floor(diff / 1000 / 60);
    if (mins < 60) {
      return `${mins} mins. ago`
    } else {
      const hrs = Math.floor(mins / 60)
      return `${hrs} hrs. ago`
    }
  }

  setAvailableBookings = async () => {
    const activeRequests = this.state.activeBookingRequests.filter(booking => booking.denied === false)
    for (const booking of activeRequests) {
      const dates = {
        from_date: booking.from_date,
        to_date: booking.to_date,
      }
      const search = await BookingInstance.post(`booking/technician/${booking.technician.id}/search/`, dates);
      if (search.data.data.length === 0) {
        const id = booking.id
        this.setState({ availableBookings: [...this.state.availableBookings, id] })
      } else {
        const filtered = this.state.availableBookings.filter(id => id === booking.id)
        this.setState({ availableBookings: filtered })
      }
    }
  }

  isBookingAvailable = (id) => {
    const isAvailable = (
      this.state.availableBookings.filter(bookingId => bookingId === id)
    ).length;
    if (isAvailable) {
      return <span style={{color: "green", marginLeft: 5, opacity: 1}}>
      <CheckCircleIcon style={{fontSize: 12}} />  Available
      </span>
    } else {
      return <span style={{color: "red", marginLeft: 5, opacity: 1}}>
      <CancelIcon style={{fontSize: 12}} />  Unavailable
      </span>
    }
  };


  render() {
    let filteredData;
    const { filterText } = this.state;
    if (
      this.props.bookings !== null &&
      this.props.bookings.isLoading === false
    ) {
      filteredData = this.renderUsers().filter(
        (item) =>
          item.technician.toLowerCase().includes(filterText.toLowerCase()) ||
          item.customer.toLowerCase().includes(filterText.toLowerCase()) ||
          item.dateFrom.toLowerCase().includes(filterText.toLowerCase()) ||
          item.dateTo.toLowerCase().includes(filterText.toLowerCase()) ||
          item.bookingStatus.toLowerCase().includes(filterText.toLowerCase())
      );
    }

    const columns = [
      {
        name: "Technician",
        selector: "technician",
        grow: 1,
        sortable: true,
      },
      {
        name: "Customer",
        selector: "customer",
        grow: 1,
        sortable: true,
      },
      {
        name: "From Date",
        selector: "dateFrom",
        grow: 1,
        sortable: true,
      },
      {
        name: "To Date",
        selector: "dateTo",
        grow: 1,
        sortable: true,
      },
      {
        name: "Status",
        selector: "bookingStatus",
        grow: 1,
        sortable: true,
      },
      {
        cell: (row) => (
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-action-user-org"
              style={{ marginTop: "13px" }}
            >
              <img src={arrow} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="edit-table-wrapper">
              {this.actionOptions(row)}
            </Dropdown.Menu>
          </Dropdown>
        ),
        selector: "action",
        allowOverflow: true,
        button: true,
        right: true,
      },
    ];

    if (
      this.props.bookings !== null &&
      this.props.bookings.isLoading === true
    ) {
      return <Spinner />;
    } else {
      return (
        <div>
          <BookingStatusModal
            show={this.state.bookingModalShow}
            onHide={() => this.setState({ bookingModalShow: false })}
            bookingId={this.state.bookingId}
          />
          <FontAwesomeIcon
            icon={faCalendarAlt}
            size="sm"
            className="fontawesome-sidemenu"
            id="fonticon-list"
          />
          <h5 className="header-lists">Bookings </h5>
          <div className="white-background-customers">
            {this.state.showBookingDrawer ? null : (
              <button
                onClick={this.openRegistrationDrawer}
                className="pending-customers-button"
              >
                <div className="pending-customers-count">
                  {this.state.activeBookingRequests.filter(booking => booking.denied === false).length}
                </div>
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  size="sm"
                />
              </button>
            )}
            <row>
              <span className="list-title">BOOKING LIST</span>
              <Link to="/BookingsMGT">
                <button
                  type="button"
                  data-testid="org-button"
                  className="dashboard-register-button"
                  id="register-button"
                >
                  New Booking
                </button>
              </Link>
            </row>
            <div className="table-search-wrapper">
              <input
                className="table-search"
                placeholder="   Search Bookings"
                value={this.filter}
                onChange={this.handleChange}
              />
            </div>
            <DataTable
              columns={columns}
              data={filteredData}
              pagination={true}
              paginationPerPage={4}
              paginationRowsPerPageOptions={[4, 10, 15]}
              sortFunction={this.customSort}
            />
          </div>

          {this.modalHandler()}
          {this.bookingRequestModalHandler()}
          {this.editBookingRequestModalHandler()}

          <Drawer
            anchor="right"
            variant="persistent"
            id="registration-drawer"
            className="registration-drawer"
            classes={{
              paper: "registration-drawer-paper",
            }}
            open={this.state.showBookingDrawer}
            onClose={this.closeBookingDrawer}
          >
            <div className="registration-drawer-content" role="presentation">
              <IconButton
                className="registration-drawer-button"
                onClick={this.closeBookingDrawer}
              >
                <ChevronRightIcon />
              </IconButton>
              <div className="customer-list-registration-title">
                <span>BOOKING REQUESTS</span>
              </div>
              <div>
                {this.state.activeBookingRequests
                  .filter(booking => booking.denied === false)
                  .map(booking => (
                    <Paper
                      key={booking.id}
                      className="customer-list-registration-customer">
                      <div className="customer-username-container">
                        <p
                          style={{ display: "inline-block" }}
                          className="customer-list-registration-username">
                          {booking.customer.user.username}
                          <span className="created_date">{this.getTimeDiff(booking.created_date)}</span>
                        </p>
                        <MoreVertIcon
                          onClick={this.handleClickPopover}
                          style={{ float: "right", cursor: "pointer" }}
                        />
                        <Popover
                          open={Boolean(this.state.anchorEl)}
                          disableScrollLock
                          anchorEl={this.state.anchorEl}
                          onClose={this.handleClosePopover}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}>
                          <Paper>
                            <MenuList>
                              <MenuItem onClick={() => this.openEditBookingRequestModal(booking)}>
                                <BorderColorIcon style={{ fontSize: 14, marginRight: 2 }} />
                                Edit Booking
                              </MenuItem>
                              <MenuItem
                                className="customer-list-registration-details"
                                onClick={() => this.openBookingRequestModal(booking)}
                              >
                                <ViewHeadlineIcon style={{ fontSize: 14, marginRight: 2 }} />  More Details
                              </MenuItem>
                            </MenuList>
                          </Paper>
                        </Popover>
                      </div>

                      <p className="customer-list-registration-details">
                        <span>Technician: </span>{booking.technician.first_name} {booking.technician.last_name}
                        <span>{this.isBookingAvailable(booking.id)}</span>
                      </p>

                      <p className="customer-list-registration-details">
                        <span>Dates: </span>
                        {String(booking.from_date).replace(/-/g, '.')}
                        {' '}-{' '}
                        {String(booking.to_date).replace(/-/g, '.')}
                      </p>

                      <p className="customer-list-registration-details">
                        <span>Remarks: </span>{booking.remarks}
                      </p>

                      <div className="customer-list-registration-button-container">
                        <Button
                          bsPrefix="custom-btn"
                          size="sm"
                          onClick={() => this.onClickAccept(booking)}
                        >
                          Accept
                        </Button>
                        <Button
                          bsPrefix="custom-btn-cancel"
                          size="sm"
                          onClick={() => this.onClickDecline(booking)}
                        >
                          Decline
                        </Button>
                      </div>
                    </Paper>
                  ))}
              </div>
            </div>
          </Drawer>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    bookings: state.bookings,
    bookingsRegistration: state.fetchBookingsRegistration,
    modal: state.toggleModal.show,
    editBooking: state.editBooking,
    deleteBooking: state.deleteBooking,
    selectedBooking: state.selectedBooking,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchBookings,
      fetchBookingsRegistration,
      editBooking,
      deleteBooking,
      selectBooking,
      showModal,
      hideModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
