import {
    FETCH_CUSTOMERS_START,
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_FAILURE,
    EDIT_CUSTOMER,
    CUSTOMER_SELECTED,
    DELETE_CUSTOMER,
    DELETE_DOCUMENT,
  } from "../types";
  import history from "../../../history";
  import { CustomerInstance, UploadInstance} from "../../../config";
  
  const fetchCustomersStart = () => {
    return {
      type: FETCH_CUSTOMERS_START,
    };
  };
  
  const fetchCustomersSuccess = (customers) => {
    return {
      type: FETCH_CUSTOMERS_SUCCESS,
      customers,
    };
  };
  
  const fetchCustomersFailure = (error) => {
    return {
      type: FETCH_CUSTOMERS_FAILURE,
      error,
    };
  };
  
  const fetchCustomerDetails = () => {
    const response = CustomerInstance.get("customer/");
    return response;
  };
  
  export const fetchCustomers = () => (dispatch) => {
    dispatch(fetchCustomersStart());
    return fetchCustomerDetails().then(
      (response) => dispatch(fetchCustomersSuccess(response.data.data)),
      (error) => dispatch(fetchCustomersFailure(error))
    );
  };
  
  export const selectCustomer = (id) => {
    return {
      type: CUSTOMER_SELECTED,
      id: id,
    };
  };
  
  export const deleteCustomer = (id) => {
    return async (dispatch) => {
      const response = await CustomerInstance.delete(`customer/${id}/`);
      dispatch({ type: DELETE_CUSTOMER, payload: response.data });
      history.push("/");
    };
  };
  
  export const editCustomer = (id) => {
    return async (dispatch) => {
      const response = await CustomerInstance.get(`customer/${id}/`);
      dispatch({ type: EDIT_CUSTOMER, payload: response.data});
      history.push({
        pathname: "/NewCustomer",
        prePopulate: response.data.data,
        populate: true,
      });
    };
  };
  

  export const deleteDocument = (id) => {
    return async (dispatch) => {
      const response = await UploadInstance.delete(`accompanying-document/${id}/`);
      dispatch({ type: DELETE_DOCUMENT, payload: response.data });
      window.location.reload();
    }
  }