import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Delete from "../../../../../assets/delete.svg";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { UploadInstance } from "../../../../../config";
import {
  deleteDocument,
  fetchCustomers,
} from "../../../../../Redux/actions/Customers/fetchCustomerAction";

import Spinner from "../../../../../components/UI/Spinner/Spinner";
import ErrorModal from "../../../../../components/UI/Modal/ErrorModal";


class ModalDoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          File: "",
          Filename: "",
          "": "",
          DateCreated: "",
          "🠾": "",
          FileSize: "",
        },
      ],
      customerId: "",
      docId: "",
      doc: null,
      loading: false,
      show: false,
      error: {},
    };
  }

  async componentDidMount() {
    await this.props.documents;
    await this.props.customerId;
  }

  submitDoc = () => {
    this.setState({ loading: true });

    const fileData = new FormData();
    fileData.append("customer", this.props.customerId);
    fileData.append("doc", this.state.doc);

    try {
      this.setState({ loading: true });
      UploadInstance.post("accompanying-document/", fileData).then(
        (response) => {
          if (response.status === 201) {
            this.setState({ loading: false });
            window.location.reload();
          }
        }
      );
    } catch (error) {
      console.log("Updating document error");
    }
  };

  uploadDoc = (syntheticEvent) => { 
    this.setState({ doc: syntheticEvent.target.files[0] })
  }

  renderTableData() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <tr>
        <td className="modaldoc-col">
          {this.props.documents.map((doc) => (
            <tr className="modaldoc-col">
              <FontAwesomeIcon
                icon={faFilePdf}
                size="1x"
                id="modaldoc-file-icon"
                className="fontawesome-sidemenu"
              />
            </tr>
          ))}
        </td>
        <td className="modaldoc-col" id="filename-col">
          {this.props.documents.map((doc) => (
            <tr className="modaldoc-col" id="filename">
              {doc.doc.split("/").pop()}
            </tr>
          ))}
        </td>
        <br />
        <td className="modaldoc-col">
          {this.props.documents.map((doc) => (
            <tr className="modaldoc-col">{doc.created_date.split("T")[0]}</tr>
          ))}
        </td>
        <br />
        <td className="modaldoc-col">
          {this.props.documents.map((doc) => (
            <tr className="modaldoc-col">
              {(doc.file_size / (1024 * 1024)).toFixed(2) + "MB"}
            </tr>
          ))}
        </td>
        <td className="modaldoc-col" style={{ cursor: "pointer" }}>
          {this.props.documents.map((doc) => (
            <tr className="modaldoc-col">
              {" "}
              <img
                src={Delete}
                alt="delete-icon"
                id="modaldoc-del-icon"
                className="del-edit-icon"
                onClick={() => {
                  this.props.deleteDocument(doc.id);
                }}
              />
            </tr>
          ))}
        </td>
      </tr>
    );
  }

  renderTableHeader() {
    let header = Object.keys(this.state.headers[0]);
    return header.map((key, index) => {
      return (
        <th key={index} className="table-headers-docs">
          {(key = key.replace(/([A-Z])/g, " $1").trim())}
        </th>
      );
    });
  }

  // close error modal
  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.show ? (
          <Modal
            show={this.props.show}
            onHide={this.props.onHide}
            className="modalDoc"
            size="md"
          >
            
            <Modal.Header closeButton>
              <Modal.Title>Available Documents</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              { this.props.documents.doc !== null && this.props.documents.length > 0 ? (
                <div className="table-docs" style={{ border: "1px solid lightgrey", padding: "6px" }}>
                  {this.renderTableHeader()}
                  {this.renderTableData()}
                </div> 
                ) 
              : ( <p>No available documents</p> )
              }
              <div>
                <br />
              </div>
              <label
                style={{
                  background: "transparent linear-gradient(180deg, #DCDCDC 0%, #DCDCDC 100%) 0% 0% no-repeat padding-box",
                  borderRadius: "10px",
                  padding: "7px",
                }}>
                <input
                  accept="application/msword, application/vnd.ms-excel,application/pdf"
                  style={{ width: "320px" }}
                  type="file"
                  onChange={this.uploadDoc}
                />{" "}
              </label>
            </Modal.Body>

            <Modal.Footer>
              <Button bsPrefix="publish-offer-btn" variant="secondary" onClick={this.submitDoc}>Submit</Button>
              <Button bsPrefix="custom-btn-cancel" variant="secondary" style={{width: "75px"}} onClick={this.props.onHide}>Close</Button>
            </Modal.Footer>

            <ErrorModal
              onHide={this.handleClose}
              handleClose={this.handleClose}
              show={this.state.show}
              error={this.state.error}
            />

          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customers: state.customers,
    deletedDocument: state.deleteDocument,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ deleteDocument, fetchCustomers }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDoc);
