import React, { Component } from "react";
import { GoogleAPIKey, SearchBoxId } from "../../../../../config";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";


Geocode.setApiKey(GoogleAPIKey);
Geocode.enableDebug();


class RegisterTechnicianMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: { lat: 53.36, lng: -6.26 },
      zoom: 4,
    };
  }


  handleSearchBox = async (map, maps) => {
    // Reference:
    // https://developers.google.com/maps/documentation/javascript/examples/places-searchbox

    // Create search box HTML element
    let input = document.createElement("input");
    input.type = "text";
    input.className = "form-control col-6";
    input.style.marginTop = "10px"
    input.style.marginLeft = "10px"
    input.id = SearchBoxId;
    input.placeholder = "Search Location";

    // Add the input element to top left of the map
    map.controls[maps.ControlPosition.TOP_LEFT].push(input);

    // SearchBox API:
    // https://developers.google.com/maps/documentation/javascript/reference/places-widget#SearchBox
    let searchBox = new maps.places.SearchBox(input);

    // Bias the SearchBox results towards current map's viewport
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds());
    });

    let markers = [];

    // Location icon
    const icon = {
      size: new maps.Size(150, 150),
      origin: new maps.Point(0, 0),
      anchor: new maps.Point(17, 34),
      scaledSize: new maps.Size(25, 25),
    };

    // If we're editing a technician
    // then we will receive their location lat and lng as props.
    if(this.props.lat && this.props.lng) {
      // Let's add the marker to the map.
      let marker = new maps.Marker({
        position: { 
          lat: Number(this.props.lat), 
          lng: Number(this.props.lng) 
        },
        map,
        draggable: true,
        icon: icon
      });
      markers.push(marker);
      marker.setMap(map);

      // Update the center of the map, so that the marker is in center.
      map.setCenter(marker.getPosition())

      // And add a listener for dragging, so that we can update the search box.
      maps.event.addListener(
        marker, 
        'dragend', 
        async () => { 
          let geocode = await Geocode.fromLatLng(
            marker.getPosition().lat(), 
            marker.getPosition().lng()
          )
          let address = geocode.results[0].formatted_address;
          let searchBox = document.getElementById(SearchBoxId);
          searchBox.value = address;
        });
    }

    searchBox.addListener("places_changed", function () {
      let places = searchBox.getPlaces();
      let bounds = new maps.LatLngBounds();

      if (places.length === 0) {
        return;
      } else {
        let warning = document.getElementById("register-tech-map-warning")
        warning.style.display = "none"
      }

      places.forEach(function (place) {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }

        // Clear out the old markers.
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];


        // Marker API:
        // https://developers.google.com/maps/documentation/javascript/reference/marker
        let marker = new maps.Marker({
          icon,
          draggable: true,
          position: place.geometry.location,
        });

        maps.event.addListener(
          marker, 
          'dragend', 
          async () => { 
            let geocode = await Geocode.fromLatLng(
              marker.getPosition().lat(), 
              marker.getPosition().lng()
            )
            let address = geocode.results[0].formatted_address;
            let searchBox = document.getElementById(SearchBoxId);
            searchBox.value = address;
          });

        markers.push(marker);
        marker.setMap(map);

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });
  };


  render() {
    return (
      <div 
        className="map-size">
        <div className="invalid-feedback"
          id="register-tech-map-warning"
          style={{
            position: "absolute",
            top: "105px",
            // left: "30px",
            display: "none",
            zIndex: 999
          }}>
            Please enter technician location.
        </div>
        <GoogleMapReact
          defaultCenter={this.state.center}
          defaultZoom={this.state.zoom}
          yesIWantToUseGoogleMapApiInternals
          options={{
            scrollwheel: false,
            fullscreenControl: false,
            mapTypeControl: false,
            panControl: false
          }}
          onGoogleApiLoaded={({ map, maps }) => this.handleSearchBox(map, maps)}
        />
      </div>
    );
  }
}

export default RegisterTechnicianMap;
