import { DELETE_DOCUMENT } from "../../actions/types";

export default (state = null, action) => {
    switch (action.type) {
      case DELETE_DOCUMENT:
        return {
          ...state,
          deleteDocument: action,
        };
      default:
        return state;
    }
  };
  