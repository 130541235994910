import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchCustomers,
  editCustomer,
  selectCustomer,
  deleteCustomer,
} from "../../../../../Redux/actions/Customers/fetchCustomerAction";
import { fetchCustomersRegistration } from "../../../../../Redux/actions/Customers/fetchCustomerRegistrationAction";
import {
  showModal,
  hideModal,
} from "../../../../../Redux/actions/UI/toggleModalAction";
import DataTable from "react-data-table-component";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import arrow from "../../../../../assets/arrow.svg";
import Pdf from "../../../../../assets/pdf.svg";
import Edit from "../../../../../assets/edit.svg";
import Delete from "../../../../../assets/delete.svg";
import Password from "../../../../../assets/password.svg";
import orderBy from "lodash/orderBy";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { BookingInstance, CustomerInstance } from "../../../../../config";
import ModalDoc from "./ModalDoc";

import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Drawer from "@material-ui/core/Drawer";
import Paper from "@material-ui/core/Paper";

class CustomerList extends Component {
  constructor() {
    super();
    this.state = {
      filterText: "",
      ModalShow: false,
      docId: [],
      showAlert: false,
      customerId: 1,
      showRegistrationDrawer: false,
      activeCustomersRegistration: [],
    };
  }

  async componentDidMount() {
    this.props.fetchCustomers();
    const customerReg = await (await this.props.fetchCustomersRegistration()).customers;
    this.setState({ activeCustomersRegistration: customerReg })
    this.closeRegistrationDrawer(); // Make sure that both drawer and CSS styles start from closed.
  }

  componentWillUnmount() {
    this.closeRegistrationDrawer();
  }

  passwordReset = async (id) => {
    await CustomerInstance.get(`customer/${id}/reset-password`).then((res) => {
      if (res.status === 200) {
        this.setState({ showAlert: true });
      }
    });
  };

  async renderDoc(id) {
    this.setState({ customerId: id });
    this.setState({ ModalShow: true });
    let docu = await BookingInstance.get(
      `accompanying-document/${id}/customer`
    );
    if (docu.data.data === undefined || null) {
      return console.log("no docs");
    } else {
      this.setState({ docId: docu.data.data });
    }
  }

  handleChange = (event) => {
    this.setState({ filterText: event.target.value });
  };

  modalHandler() {
    if (this.props.modal) {
      return (
        <MDBContainer
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "0px 0px 0px #888888",
            padding: "2%",
          }}
        >
          <MDBModal
            isOpen={this.props.modal}
            toggle={this.props.modal}
            centered
            size="sm"
          >
            <MDBModalHeader>Delete</MDBModalHeader>
            <MDBModalBody>Are you sure ?</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="light" onClick={this.props.hideModal} size="sm">
                Cancel
              </MDBBtn>
              <MDBBtn
                color="danger"
                onClick={() => {
                  if (this.selectedCustomer !== null) {
                    this.props.deleteCustomer(this.props.selectedCustomer.id);
                    this.props.hideModal();
                  }
                }}
                size="sm"
              >
                Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
    }
  }

  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field]) {
        return row[field].toLowerCase();
      }
      return row[field];
    };
    return orderBy(rows, handleField, direction);
  };

  openRegistrationDrawer = () => {
    this.setState({ showRegistrationDrawer: true });
  };

  closeRegistrationDrawer = () => {
    this.setState({ showRegistrationDrawer: false });
    this.props.fetchCustomersRegistration(); // Check for updates.
  };

  actionOptions = (row) => {
    return (
      <>
        <Dropdown.Item onClick={() => this.props.editCustomer(row.id)}>
          <div className="table-edit">
            <img src={Edit} alt="" /> Edit
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            this.renderDoc(row.id);
          }}
        >
          <div className="table-pdf">
            <img src={Pdf} alt="" /> Documents
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => this.passwordReset(row.id)}>
          <div className="table-password">
            <img src={Password} alt="" /> Reset Pass
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            this.props.selectCustomer(row.id);
            this.props.showModal();
          }}
        >
          <div className="table-delete">
            <img src={Delete} alt="" /> Delete
          </div>
        </Dropdown.Item>
      </>
    );
  };

  onClickAccept = async (customer) => {
    try {
      await CustomerInstance.get(`customer/${customer.id}/activate`);
      const newCustomersRegistration = this.state.activeCustomersRegistration.filter((c) => c.id !== customer.id); 
      this.setState({ activeCustomersRegistration: newCustomersRegistration })
    } catch (error) {
      console.log("Activate customer API error!");
    }
  };

  onClickDecline = async (customer) => {
    try {
      await CustomerInstance.delete(`customer/${customer.id}`);
      const newCustomersRegistration = this.state.activeCustomersRegistration.filter((c) => c.id !== customer.id); 
      this.setState({ activeCustomersRegistration: newCustomersRegistration })
    } catch (error) {
      console.log("Delete customer API error!");
    }
  };

  renderUsers() {
    if (
      this.props.customers !== null &&
      this.props.customers.isLoading === false
    ) {
      return this.props.customers.customers.customers.map((customer) => {
        return {
          id: customer.id,
          username: customer.user.username,
          name: customer.user.first_name,
          lastName: customer.user.last_name,
          email: customer.email_address,
          telephone: customer.telephone_number,
          edit: (
            <MDBBtn
              color="primary"
              size="sm"
              onClick={() => this.props.editCustomer(customer.id)}
            >
              Edit
            </MDBBtn>
          ),
          delete: (
            <MDBBtn
              color="danger"
              size="sm"
              onClick={() => {
                this.props.selectCustomer(customer.id);
                this.props.showModal();
              }}
            >
              Delete
            </MDBBtn>
          ),
          docs: (
            <MDBBtn color="primary" size="sm">
              Documents
            </MDBBtn>
          ),
          password: (
            <MDBBtn color="primary" size="sm">
              Reset Password
            </MDBBtn>
          ),
        };
      });
    }
  }

  render() {
    let filteredData;
    const { filterText } = this.state;
    if (
      this.props.customers !== null &&
      this.props.customers.isLoading === false
    ) {
      filteredData = this.renderUsers().filter(
        (item) =>
          item.username.toLowerCase().includes(filterText.toLowerCase()) ||
          item.name.toLowerCase().includes(filterText.toLowerCase()) ||
          item.lastName.toLowerCase().includes(filterText.toLowerCase()) ||
          item.email.toLowerCase().includes(filterText.toLowerCase())
      );
    }

    const columns = [
      {
        name: "Username",
        selector: "username",
        grow: 1,
        sortable: true,
      },
      {
        name: "Name",
        selector: "name",
        grow: 1,
        sortable: true,
      },
      {
        name: "Last Name",
        selector: "lastName",
        grow: 1,
        sortable: true,
      },
      {
        name: "Email",
        selector: "email",
        grow: 1,
        sortable: true,
      },
      {
        name: "Telephone",
        selector: "telephone",
        grow: 1,
        sortable: true,
      },
      {
        cell: (row) => (
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-action-user-org"
              style={{ marginTop: "13px" }}
            >
              <img src={arrow} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="edit-table-wrapper">
              {this.actionOptions(row)}
            </Dropdown.Menu>
          </Dropdown>
        ),
        selector: "action",
        allowOverflow: true,
        button: true,
        right: true,
      },
    ];

    if (
      this.props.customers !== null &&
      this.props.customers.isLoading === true
    ) {
      return <Spinner />;
    } else {
      return (
        <React.Fragment>
          <div>
            <FontAwesomeIcon
              icon={faUserFriends}
              size="sm"
              className="fontawesome-sidemenu"
              id="fonticon-list"
            />
            <h5 className="header-lists">Customers</h5>
            <div className="white-background-customers">
              {this.state.showRegistrationDrawer ? null : (
                <button
                  onClick={this.openRegistrationDrawer}
                  className="pending-customers-button"
                >
                  <div className="pending-customers-count">
                    {this.state.activeCustomersRegistration !== null
                      ? this.state.activeCustomersRegistration
                          .length
                      : null}
                  </div>
                  <FontAwesomeIcon icon={faUserFriends} size="sm" />
                </button>
              )}

              {this.state.showAlert === true ? (
                <div className="alert alert-success" role="alert">
                  Password successfully updated - new password can be accessed
                  via email
                </div>
              ) : null}
              <row>
                <span className="list-title">CUSTOMER LIST</span>
                <Link to="/NewCustomer">
                  <button
                    type="button"
                    data-testid="org-button"
                    className="dashboard-register-button"
                    id="register-button"
                  >
                    New Customer
                  </button>
                </Link>
              </row>
              <div className="table-search-wrapper">
                <input
                  className="table-search"
                  placeholder="   Search Customers"
                  value={this.filter}
                  onChange={this.handleChange}
                />
              </div>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination={true}
                paginationPerPage={4}
                paginationRowsPerPageOptions={[4, 10, 15]}
                sortFunction={this.customSort}
              />
              <div>{this.modalHandler()}</div>
            </div>
            <ModalDoc
              customerId={this.state.customerId}
              documents={this.state.docId}
              show={this.state.ModalShow}
              onHide={() => this.setState({ ModalShow: false })}
            />
            <Drawer
              anchor="right"
              variant="persistent"
              id="registration-drawer"
              className="registration-drawer"
              classes={{
                paper: "registration-drawer-paper",
              }}
              open={this.state.showRegistrationDrawer}
              onClose={this.toggleRegistrationDrawer}
            >
              <div className="registration-drawer-content" role="presentation">
                <IconButton
                  className="registration-drawer-button"
                  onClick={this.closeRegistrationDrawer}
                >
                  <ChevronRightIcon />
                </IconButton>
                <div className="customer-list-registration-title">
                  <span>NEW CUSTOMERS</span>
                </div>
                <div>
                  {this.state.activeCustomersRegistration.map(
                    (customer) => (
                      <Paper
                        key={customer.user.username}
                        className="customer-list-registration-customer"
                      >
                        <p className="customer-list-registration-username">
                          {customer.user.username}
                        </p>
                        <p className="customer-list-registration-details">
                          <span>Name: </span>
                          {customer.user.first_name}
                        </p>
                        <p className="customer-list-registration-details">
                          <span>Last Name: </span> {customer.user.last_name}
                        </p>
                        <p className="customer-list-registration-details">
                          <span>Email: </span>
                          {customer.email_address}
                        </p>
                        <p className="customer-list-registration-details">
                          <span>Telephone: </span>
                          {customer.telephone_number}
                        </p>
                        <div className="customer-list-registration-button-container">
                          <Button
                            bsPrefix="custom-btn"
                            size="sm"
                            onClick={() => this.onClickAccept(customer)}
                          >
                            Accept
                          </Button>
                          <Button
                            bsPrefix="custom-btn-cancel"
                            size="sm"
                            onClick={() => this.onClickDecline(customer)}
                          >
                            Decline
                          </Button>
                        </div>
                      </Paper>
                    )
                  )}
                </div>
              </div>
            </Drawer>
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    customers: state.customers,
    customersRegistration: state.fetchCustomersRegistration,
    modal: state.toggleModal.show,
    selectedCustomer: state.selectedCustomer,
    editCustomer: state.editCustomer,
    deletedCustomer: state.deleteCustomer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchCustomers,
      fetchCustomersRegistration,
      editCustomer,
      selectCustomer,
      deleteCustomer,
      showModal,
      hideModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
