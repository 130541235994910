export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const FETCH_ORGANIZATIONS = "FETCH_ORGANIZATIONS";
export const FETCH_ANALYTICS = "FETCH_ANALYTICS";
export const FETCH_YEARS = "FETCH_YEARS";
export const FETCH_REGIONS = "FETCH_REGIONS";
export const YEAR_SELECTED = "YEAR_SELECTED";
export const REGION_SELECTED = "REGION_SELECTED";
export const FETCH_USERS_START = "FETCH_USERS_START";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
export const EDIT_USER = "EDIT_USER";
export const FETCH_ORGS_START = "FETCH_ORGS_START";
export const FETCH_ORGS_SUCCESS = "FETCH_ORGS_SUCCESS";
export const FETCH_ORGS_FAILURE = "FETCH_ORGS_FAILURE";
export const EDIT_ORGS = "EDIT_ORGS";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const SHOW_NEWS_MODAL = "SHOW_NEWS_MODAL";
export const HIDE_NEWS_MODAL = "HIDE_NEWS_MODAL";
export const TOGGLE_NEWS_MODAL = "TOGGLE_NEWS_MODAL";
export const DELETE_ORG = "DELETE_ORG";
export const ORG_SELECTED = "ORG_SELECTED";
export const DELETE_USER = "DELETE_USER";
export const USER_SELECTED = "USER_SELECTED";
export const SHOW_REGISTER = "SHOW_REGISTER";
export const SHOW_MEMBERS = "SHOW_MEMBERS";
export const SHOW_TECHNICIANS = "SHOW_TECHNICIANS";
export const SHOW_BOOKINGS = "SHOW_BOOKINGS";
export const CLOSE_REGMEMB = "CLOSE_REGMEMB";
export const CLEAR_CSS = "CLEAR_CSS";
export const REMOVE_CLEAR_CSS = "REMOVE_CLEAR_CSS";

export const FETCH_TECHNICIANS_START = "FETCH_TECHNICIANS_START";
export const FETCH_TECHNICIANS_SUCCESS = "FETCH_TECHNICIANS_SUCCESS";
export const FETCH_TECHNICIANS_FAILURE = "FETCH_TECHNICIANS_FAILURE";
export const EDIT_TECHNICIAN = "EDIT_TECHNICIAN";
export const TECHNICIAN_SELECTED = "TECHNICIAN_SELECTED";
export const DELETE_TECHNICIAN = "DELETE_TECHNICIAN";

export const FETCH_ADMINS_START = "FETCH_ADMINS_START";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_FAILURE = "FETCH_ADMINS_FAILURE";
export const EDIT_ADMIN = "EDIT_ADMIN";
export const ADMIN_SELECTED = "ADMIN_SELECTED";
export const DELETE_ADMIN = "DELETE_ADMIN";

export const FETCH_CUSTOMERS_START = "FETCH_CUSTOMERS_START";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_FAILURE = "FETCH_CUSTOMERS_FAILURE";

export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const CUSTOMER_SELECTED = "CUSTOMER_SELECTED";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";

export const FETCH_CUSTOMERS_REGISTRATION_START = "FETCH_CUSTOMERS_REGISTRATION_START";
export const FETCH_CUSTOMERS_REGISTRATION_SUCCESS = "FETCH_CUSTOMERS_REGISTRATION_SUCCESS";
export const FETCH_CUSTOMERS_REGISTRATION_FAILURE = "FETCH_CUSTOMERS_REGISTRATION_FAILURE";

export const DELETE_DOCUMENT = "DELETE_DOCUMENT";

export const FETCH_BOOKINGS_START = "FETCH_BOOKINGS_START";
export const FETCH_BOOKINGS_SUCCESS = "FETCH_BOOKINGS_SUCCESS";
export const FETCH_BOOKINGS_FAILURE = "FETCH_BOOKINGS_FAILURE";
export const EDIT_BOOKING = "EDIT_BOOKING";
export const BOOKING_SELECTED = "BOOKING_SELECTED";
export const DELETE_BOOKING = "DELETE_BOOKING";

export const FETCH_BOOKINGS_REGISTRATION_START = "FETCH_BOOKINGS_REGISTRATION_START";
export const FETCH_BOOKINGS_REGISTRATION_SUCCESS = "FETCH_BOOKINGS_REGISTRATION_SUCCESS";
export const FETCH_BOOKINGS_REGISTRATION_FAILURE = "FETCH_CUSTOMERS_REGISTRATION_FAILURE";

export const FETCH_OFFERS_START = "FETCH_OFFERS_START";
export const FETCH_OFFERS_SUCCESS = "FETCH_OFFERS_SUCCESS";
export const FETCH_OFFERS_FAILURE = "FETCH_OFFERS_FAILURE";
export const EDIT_OFFER = "EDIT_OFFER";
export const OFFER_SELECTED = "OFFER_SELECTED";
export const DELETE_OFFER = "DELETE_OFFER";

export const FETCH_POSTS_START = "FETCH_POSTS_START";
export const FETCH_POSTS_SUCCESS = "FETCH_POSTS_SUCCESS";
export const FETCH_POSTS_FAILURE = "FETCH_POSTS_FAILURE";
export const EDIT_POST = "EDIT_POST";
export const POST_SELECTED = "POST_SELECTED";
export const DELETE_POST = "DELETE_POST";

export const USER_ALERT = "USER_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const SHOW_DISPATCH_MODAL = "SHOW DISPATCH MODAL";
export const HIDE_DISPATCH_MODAL = "HIDE DISPATCH MODAL";
