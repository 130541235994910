import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

import { BookingInstance, TechInstance } from "../../../../../config";
import { connect } from "react-redux";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import ErrorModal from "../../../../../components/UI/Modal/ErrorModal";
import { DateRangePicker } from "react-date-range";
import moment from "moment";

class BookingsMGT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
      technician: "",
      customer: "",
      available_technicians: [],
      techInformation: [],
      remarks: "none",
      location: "",
      from_date: "2022-11-10",
      to_date: "2022-12-12",
      showAlert: false,
      loading: false,
      error: {},
      edit: false,

      customerError: false,
      technicianError: false 
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
  }


  componentDidMount = async () => {
    if (this.props.location.prePopulate) {
      await this.setState({
        selectionRange: {
          startDate: moment(this.props.location.prePopulate.from_date)._d,
          endDate: moment(this.props.location.prePopulate.to_date)._d,
          key: "selection",
        },
        customer: this.props.location.prePopulate.customer.id,
        technician: this.props.location.prePopulate.technician.id,
        remarks: this.props.location.prePopulate.remarks,
        location: this.props.location.prePopulate.location,
        edit: true,
      });
    }
  };

  onCancel = async () => {
    await this.props.history.push("/Dashboard");
  };

  handleSelect = async (ranges) => {
    this.setState({
      selectionRange: {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        key: "selection",
      },
    });
    let filterDates = {
      filter: {
        from_date: moment(ranges.selection.startDate).format("YYYY-MM-DD"),
        to_date: moment(ranges.selection.endDate).format("YYYY-MM-DD"),
        show_tech_info: true,
      },
    };
    try {
      let response = await TechInstance.post("technician/search/", filterDates);
      this.setState({
        available_technicians: response.data.data.available_technicians,
      });
      this.techInfo();
    } catch (error) {
      console.log(error);
    }
  };

  techInfo = async () => {
    let techInformation = [];
    this.setState({ loading: true });
    try {
      this.state.available_technicians.forEach((tech) => {
        BookingInstance.post(`booking/technician/${tech.id}/search/`, {
          from_date: moment(this.state.selectionRange.startDate).format(
            "YYYY-MM-DD"
          ),
          to_date: moment(this.state.selectionRange.endDate).format(
            "YYYY-MM-DD"
          ),
        }).then((response) => {
          if (response.data.data.length > 0) {
            if (
              response.data.data[0].from_date >=
                moment(this.state.selectionRange.startDate).format(
                  "YYYY-MM-DD"
                ) ||
              response.data.data[response.data.data.length - 1].to_date <=
                moment(this.state.selectionRange.endDate).format("YYYY-MM-DD")
            ) {
            } else {
              TechInstance.post(`technician/${tech.id}`).then((response) => {
                techInformation.push(response.data.data);
              });
            }
          } else if (response.data.data.length === 0) {
            TechInstance.post(`technician/${tech.id}`).then((response) => {
              techInformation.push(response.data.data);
              this.setState({ techInformation: techInformation });
            });
            this.setState({ techInformation: techInformation });
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
    this.setState({ techInformation: techInformation, loading: false });
  };


  handleChange = async (event) => {
    await this.setState({ value: event.target.value });
  };

  onChangeTechnician = (event) => {
    this.props.technicians.technicians.technicians.map((tech) =>  {
      let technician = `${tech.first_name} ${tech.last_name} - ${tech.id}`;
      if (technician === event.target.value) {
        this.setState({ technician: tech.id });
      }
    });
  };

  onChangeRemarks = (event) => {
    this.setState({ remarks: event.target.value });
  };

  onChangeLocation = (event) => {
    this.setState({ location: event.target.value });
  };

  onChangeCustomer = (event) => {
    this.props.customers.customers.customers.map((customer) => {
      let customerName = `${customer.user.first_name} ${customer.user.last_name} - ${customer.id}`;
      if (customerName === event.target.value) {
        this.setState({ customer: customer.id });
      }
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  checkIfRequiredInputEntered() {
    this.setState({ customerError: !this.state.customer})
    this.setState({ technicianError: !this.state.technician})
}

  onSubmit = async () => {
    this.checkIfRequiredInputEntered();
    if (this.props.location.prePopulate) {
      try {
        const bookingPatch = {
          from_date: moment(this.state.selectionRange.startDate).format(
            "YYYY-MM-DD"
          ),
          to_date: moment(this.state.selectionRange.endDate).format(
            "YYYY-MM-DD"
          ),
          technician: this.state.technician,
          customer: this.state.customer,
          remarks: this.state.remarks,
          location: this.state.location,
        };
        await BookingInstance.patch(
          `booking/${this.props.location.prePopulate.id}`,
          bookingPatch
        );
        this.props.history.push("/Bookings");
      } catch (error) {
        console.log("Edit Booking Error");
      }
    } else {
      try {
        const booking = {
          from_date: moment(this.state.selectionRange.startDate).format(
            "YYYY-MM-DD"
          ),
          to_date: moment(this.state.selectionRange.endDate).format(
            "YYYY-MM-DD"
          ),
          technician: this.state.technician,
          customer: this.state.customer,
          remarks: this.state.remarks,
          location: this.state.location,
        };
        await BookingInstance.post(`booking/`, booking).then((res) => {
          if (res.status === 201) {
            this.setState({ showAlert: true });
          }
        });
        this.props.history.push("/Bookings");
      } catch (error) {
        console.log("Creating Booking Error");
        this.setState({ loading: false });
      }
    }
  };

  renderTechniciansOptions = () => {
    if (this.props.location.prePopulate) {
      let technicians = [...this.props.technicians.technicians.technicians];
      technicians.splice(
        this.props.technicians.technicians.technicians.findIndex(
          (technician) =>
            technician.id === this.props.location.prePopulate.technician.id
        ),
        1
      );
      return (
        <React.Fragment>
          <option>
            {this.props.location.prePopulate.technician.first_name}{" "}
            {this.props.location.prePopulate.technician.last_name}{" "}-{" "}
            {this.props.location.prePopulate.technician.id}{" "}--current
          </option>
          {technicians.map((technician) => {
            if (
              technician.available_from <
              moment(this.state.selectionRange.startDate).format("YYYY-MM-DD")
            ) {
              return (
                <option key={technician.id} value={technician.value}>
                  {technician.first_name} {technician.last_name} - {technician.id}
                </option>
              );
            } else {
              return null;
            }
          })}
        </React.Fragment>
      );
    } else {
      return this.state.techInformation.map((tech) => {
        return (
          <option key={tech.id} value={tech.value}>
            {tech.first_name} {tech.last_name} - {tech.id}
          </option> 
        );
      });
    }
  };

  renderCustomersOptions() {
    if (this.props.location.prePopulate) {
      let customers = [...this.props.customers.customers.customers];
      customers.splice(
        this.props.customers.customers.customers.findIndex(
          (customer) =>
            customer.id === this.props.location.prePopulate.customer.id
        ),
        1
      );

      return (
        <React.Fragment>
          <option key={this.props.location.prePopulate.customer.id}>
            {this.props.location.prePopulate.customer.user.first_name}{" "}
            {this.props.location.prePopulate.customer.user.last_name}{" "}-{" "} 
            {this.props.location.prePopulate.customer.id}{" "}--current
          </option>

          {customers.map((customer) => {
            return (
              <option key={customer.id}>
                {customer.user.first_name} {customer.user.last_name} - {customer.id}
              </option>
            );
          })}
        </React.Fragment>
      );
    } else {
      return this.props.customers.customers.customers.map((customer) => {
        const { id } = customer;
        return (
          <option key={id}>
            {customer.user.first_name} {customer.user.last_name} - {customer.id}
          </option>
        );
      });
    }
  }

  render() {
    return (
      <>
        <FontAwesomeIcon
          icon={faCalendarAlt}
          size="sm"
          className="fontawesome-sidemenu"
          id="fonticon-list"
        />
        <h5 className="header-lists">Bookings </h5>
        <div className="white-background-bookings">
          <div className="grid-container-booking">
            <div className="Booking-form">
              {this.state.showAlert === true ? (
                <div className="alert alert-success" role="alert">
                  Booking successfully { this.props.location.prePopulate ? "edited" : "created" }
                </div>
              ) : null}
              <Form>
                <Form.Group>
                  <Form.Label className="label-new-booking">
                    Customer
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue="Select customer"
                    onChange={this.onChangeCustomer}
                  >
                    {!this.props.location.prePopulate ? (
                      <option>Select Customer</option>
                    ) : null}
                    {this.renderCustomersOptions()}
                  </Form.Control>
                  {this.state.customerError && <div className="invalid-feedback" style={{display:"block"}}>Customer must be selected</div>}

                  <Form.Label className="label-new-booking">
                    Technician
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue="Select technician"
                    onChange={this.onChangeTechnician}
                  >
                    {!this.props.location.prePopulate ? (
                      <option>Select Technician</option>
                    ) : null}
                    {this.renderTechniciansOptions()}
                  </Form.Control>
                  {this.state.technicianError && <div className="invalid-feedback" style={{display:"block"}}>Technician must be selected</div>}

                  <Form.Label className="label-new-booking" id="location-label">
                    Location
                  </Form.Label>
                  <Form.Control
                    as="input"
                    defaultValue={this.state.location}
                    value={this.state.location}
                    controlId="controlTextLocation"
                    onChange={this.onChangeLocation}
                  />

                  <Form.Label className="label-new-booking" id="remarks-label">
                    Remarks
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    defaultValue={this.state.remarks === "none" ? "" : this.state.remarks}
                    style={{ height: "122px" }}
                    controlId="controlTextarea1"
                    onChange={this.onChangeRemarks}
                  />
                </Form.Group>
              </Form>
            </div>
            <div className="Booking-calendar">
              <div className="pick-date">Please, pick a date first</div>
              <div className="calendar-border">
                <DateRangePicker
                  ranges={[this.state.selectionRange]}
                  onChange={this.handleSelect}
                  showClearDate={true}
                />
              </div>
            </div>
            <div className="new-booking-title">
              <h6 className="title">
                {this.state.edit === true ? "EDIT BOOKING" : "NEW BOOKING"}
              </h6>
            </div>
          </div>
          <div className="button-submit-booking">
            <Button
              bsPrefix="custom-btn"
              size="md"
              style={{ marginRight: "20px" }}
              onClick={this.onSubmit}
            >
              {this.state.edit === true ? "Edit Booking": "Submit Booking"}
            </Button>
            <Button
              bsPrefix="custom-btn-cancel"
              size="md"
              onClick={this.onCancel}
            >
              Cancel
            </Button>
            <ErrorModal
              onHide={this.handleClose}
              handleClose={this.handleClose}
              show={this.state.show}
              error={this.state.error}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
  userId: state.security.user.user_id,
  customers: state.customers,
  technicians: state.technicians,
  bookings: state.bookings,
});

export default connect(mapStateToProps, null)(BookingsMGT);
