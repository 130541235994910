import React, { Component } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import placeHolderImage from "../../../../../assets/images/placeholderImage.png";
import ErrorModal from "../../../../../components/UI/Modal/ErrorModal";
import { BookingInstance } from "../../../../../config";
import {
  deleteOffer,
  editOffer,
  fetchOffers,
  selectOffer,
} from "../../../../../Redux/actions/Offers/fetchOfferActions";
import {
  hideModal,
  showModal,
} from "../../../../../Redux/actions/UI/toggleModalAction";
import ImageModal from "./ImageModal";

class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      content: "",
      discountPercentage: 0,
      expirationDate: "",
      showAlert: false,
      imageUrl: "",
      originalImageUrl: "",
      showImageModal: false,
      image: null,
      imagePreviewUrl: "",
      error: {},
      loading: false,
      show: false,
      file: "",
      edit: false,

      // Field Errors
      titleError: false,
      contentError: false,
      expirationDateError: false,
      imageError: false,
    };
    this.onChangeImage = this.onChangeImage.bind(this);
  }

  async componentDidMount() {
    this.props.fetchOffers();
    if (this.props.location.prePopulate) {
      this.setState({
        title: this.props.location.prePopulate.title,
        content: this.props.location.prePopulate.content,
        imageUrl: this.props.location.prePopulate.img_url,
        originalImageUrl: this.props.location.prePopulate.img_url,
        discountPercentage: this.props.location.prePopulate.discount_percentage,
        expirationDate: this.props.location.prePopulate.expiration_date,
        edit: true,
      });
    }
  }

  onCancelImg = () => {
    if (this.props.location.prePopulate) {
      this.setState({ imageUrl: this.state.originalImageUrl });
    } else {
      this.setState({ image: null });
      this.setState({ imageUrl: "" });
    }
  };

  removeImage = () => {
    this.setState({ image: null });
    this.setState({ imageUrl: "" });
    this.setState({ originalImageUrl: "N/A" });
  };

  handleChange = (event) => {
    this.setState({ filterText: event.target.value });
  };

  onChangeTitle = (event) => {
    this.setState({ title: event.target.value });
  };

  onChangeContent = (event) => {
    this.setState({ content: event.target.value });
  };

  onChangeDiscount = (event) => {
    this.setState({ discountPercentage: event.target.value });
  };

  onChangeDate = (event) => {
    this.setState({ expirationDate: event.target.value });
  };

  showModal() {
    this.setState({ showImageModal: true });
  }

  onChangeUrl = (event) => {
    let url = event.target.value;
    this.setState({ imageUrl: url });
    document.getElementById("preview-image").src = url;
  };

  onChangeImage = (event) => {
    event.preventDefault();
    let image = event.target.files[0];
    this.setState({ image: image });

    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
      this.setState({ imageUrl: reader.result });
      this.setState({ originalImageUrl: reader.result });
    };

    reader.readAsDataURL(file);
  };

  handleUpload = (event) => {
    let image = this.state.files;
    let formdata = new FormData();
    formdata.append("image", image);
  };

  checkIfRequiredInputEntered() {
    this.setState({ titleError: !this.state.title });
    this.setState({ contentError: !this.state.content });
    this.setState({ expirationDateError: !this.state.expirationDate });
  }

  onSubmit = async () => {
    this.checkIfRequiredInputEntered();

    try {
      if (this.props.location.prePopulate) {
        const patch = {
          title: this.state.title,
          content: this.state.content,
          expiration_date: this.state.expirationDate,
          discount_percentage: this.state.discountPercentage,
        };

        if (this.state.imageUrl && this.state.imageUrl !== "N/A") {
          patch["image_url"] = this.state.imageUrl;
        } else {
          patch["image_url"] = "N/A";
        }

        await BookingInstance.patch(
          `offers/${this.props.location.prePopulate.id}/`,
          patch
        );
        await this.props.history.push("/Dashboard");
      } else {
        const formData = new FormData();
        formData.append("title", this.state.title);
        formData.append("content", this.state.content);
        formData.append("expiration_date", this.state.expirationDate);
        formData.append("discount_percentage", this.state.discountPercentage);

        if (this.state.image) {
          formData.append("image", this.state.image);
        } else {
          formData.append("image_url", this.state.imageUrl);
        }

        await BookingInstance.post("offers/", formData).then((res) => {
          if (res.status === 201) {
            this.setState({ showAlert: true });
          }
        });
        await this.props.history.push("/Dashboard");
      }
    } catch (error) {
      console.log("Offers API error");
    }
  };

  onCancel = async () => {
    await this.props.history.push("/Dashboard");
  };

  getImagePreview = () => {
    if (this.state.imageUrl && this.state.imageUrl !== "N/A") {
      return (
        <div id="preview-img-container">
          <img id="preview-image" alt="preview" src={this.state.imageUrl} />
          <FontAwesomeIcon
            icon={faTimesCircle}
            onClick={this.removeImage}
            id="preview-img-close"
          />
        </div>
      );
    } else {
      return <img id="preview-image" alt="preview" src={placeHolderImage} />;
    }
  };

  render() {
    return (
      <div>
        <FontAwesomeIcon
          icon={faFileUpload}
          size="sm"
          className="fontawesome-sidemenu"
          id="fonticon-list"
        />
        <h5 className="header-lists">News & Offers</h5>
        <div className="white-background-offers">
          <row>
            <span className="list-title">
              {this.state.edit === true ? "EDIT OFFER" : "NEW OFFER"}
            </span>
          </row>
          <div>
            <br />
            <Form controlId="form-offer">
              {this.state.showAlert === true ? (
                <div className="alert alert-success" role="alert">
                  Offer successfully { this.props.location.prePopulate ? "edited" : "created" }
                </div>
              ) : null}

              <Form.Row>
                <Form.Group md="6" sm="12" as={Col} controlId="formGridTitle">
                  <Form.Label className="labels-offers">Title</Form.Label>
                  <Form.Control
                    value={this.state.title}
                    onChange={this.onChangeTitle}
                    type="text"
                    placeholder="Title here"
                  />
                  {this.state.titleError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Title is required
                    </div>
                  )}
                </Form.Group>
                <Form.Group md="5" sm="10" as={Col} controlId="formGridMedia" style={{marginLeft:"30px"}}>
                  <Form.Label className="labels-offers">
                    <FontAwesomeIcon
                      icon={faFileUpload}
                      style={{ marginRight: "2px", color: "#202F81" }}
                      size="sm"
                    />
                    Add media
                  </Form.Label>
                  <div className="select-image-button-container">
                    <Button
                      size="sm"
                      className="select-image-button"
                      style={{display: "inline-block"}}
                      onClick={() => this.showModal()}
                    >
                      Select Image
                    </Button>
                    {(!this.state.imageUrl || this.state.imageUrl == "N/A") && <p style={{display: "inline-block", marginBottom: 0, fontSize: 11}}>No file chosen</p>}
                  </div>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group
                  md="6"
                  sm="12"
                  as={Col}
                  controlId="formGridTextPost"
                >
                  <Form.Label className="labels-offers">Main Text</Form.Label>
                  <Form.Control
                    value={this.state.content}
                    as="textarea"
                    rows={8}
                    placeholder="Main text"
                    onChange={this.onChangeContent}
                  />
                  {this.state.contentError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Offer description is required.
                    </div>
                  )}
                </Form.Group>
                <Form.Group md="6" sm="12" as={Col}>
                  <Form.Row className="imgPreview col">
                    {this.getImagePreview()}
                  </Form.Row>
                </Form.Group>
              </Form.Row>

              <Form.Row style={{justifyContent: "flex-start"}}>
                <Form.Group
                  lg="3"
                  md="6"
                  sm="12"
                  as={Col}
                  controlId="formGridDiscount"
                >
                  <Form.Label className="labels-offers">
                    Discount Percentage
                  </Form.Label>
                  <Form.Control
                    value={this.state.discountPercentage}
                    type="number"
                    min="0"
                    placeholder="10%"
                    onChange={this.onChangeDiscount}
                  />
                </Form.Group>
                <Form.Group
                  lg="3"
                  md="6"
                  sm="12"
                  as={Col}
                  controlId="formGridDate"
                >
                  <Form.Label
                    className="labels-offers "
                    // style={{ marginLeft: "109px" }}
                  >
                    Expiration Date
                  </Form.Label>
                  <Form.Control
                    value={this.state.expirationDate}
                    type="date"
                    onChange={this.onChangeDate}
                  />
                  {this.state.expirationDateError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Expiration date is required.
                    </div>
                  )}
                </Form.Group>
              </Form.Row>

              <br />
              <br />
              <Form.Row>
                <Form.Group id="news-offers-btns" as={Col} controlId="formGridEmail">
                    <Button
                      bsPrefix="publish-offer-btn"
                      size="md"
                      style={{ marginRight: "20px" }}
                      onClick={this.onSubmit}
                    >
                      {this.state.edit === true
                        ? "Edit Offer"
                        : "Publish Offer"}
                    </Button>
                    <Button
                      bsPrefix="custom-btn-cancel"
                      size="md"
                      onClick={this.onCancel}
                    >
                      Cancel
                    </Button>
                </Form.Group>
              </Form.Row>
              <ImageModal
                show={this.state.showImageModal}
                onHide={() => this.setState({ showImageModal: false })}
                imageUrl={this.state.imageUrl}
                removeImage={this.onCancelImg}
                onChangeUrl={this.onChangeUrl}
                image={this.state.image}
                onChangeImage={(event) => this.onChangeImage(event)}
                handleUpload={this.handleUpload}
              />
              <ErrorModal
                onHide={this.handleClose}
                handleClose={this.handleClose}
                show={this.state.show}
                error={this.state.error}
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    offers: state.offers,
    security: state.security,
    modal: state.toggleModal.show,
    selectOffer: state.selectOffer,
    selectedOffer: state.selectedOffer,
    editOffer: state.editOffer,
    deletedOffer: state.deleteOffer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchOffers, editOffer, selectOffer, deleteOffer, showModal, hideModal },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Offers);
