import React, { Component } from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../Redux/actions/Users/securityActions";
import classnames from "classnames";
import Spinner from "../../components/UI/Spinner/Spinner";
import marvelImg from "../../assets/images/marvel-icon.png";
// import axios from "axios";
import Checkbox from "../../components/Checkbox";
import moment from "moment";
import ErrorModal from "../../components/UI/Modal/ErrorModal";


class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      rememberMe: false,
      valUsername: "",
      valPassword: "",
      show: false,
      loading: false,
      error: {},
      hidden: true,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const username = rememberMe ? localStorage.getItem("username") : "";
    this.setState({ username, rememberMe });
  }

  componentDidUpdate = async (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props !== prevProps) {
      if(this.props.errors.response) {
        if (this.props.errors.response.status === 401 ) {
          await this.setState({ show: true });
        }
      }
      this.setState({ loading: false });
    }
  };


  // Check input fields for errors
  validate = () => {
    let isError = false;

    if (this.state.username.length < 3 || this.state.username.length > 100) {
      isError = true;
      this.setState({ loading: false });
      this.setState({ valUsername: "Username must be between 3 and 100 characters" });
    }

    if (this.state.password.length < 3 || this.state.password.length > 100) {
      isError = true;
      this.setState({ loading: false });
      this.setState({ valPassword: "Password  must be between 3 and 100 characters" });
    }

    return isError;
  };

  onSubmit(e) {
    e.preventDefault();

    const { username, rememberMe } = this.state;
    localStorage.setItem("rememberMe", rememberMe);
    localStorage.setItem("username", rememberMe ? username : "");

    this.setState({
      loading: true,
      valUsername: "",
      valPassword: "",
    });

    const err = this.validate();

    if (!err) {
      const LoginRequest = {
        username: this.state.username,
        password: this.state.password,
      };
      this.props.login(LoginRequest);
    }
  }

 

  handleChange = (event) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;

    this.setState({ [input.name]: value });
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // close error modal
  handleClose = () => {
    this.setState({ show: false });
  };

  componentWillUnmount() {
    this.setState({ show: false });
  }

  render() {
    const st = this.state;
    let form = (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <label htmlFor="username" className="form-field-label">
            Username
          </label>
          <input
            type="text"
            className={classnames("form-control form-control-lg form-input-field", {
              "is-invalid": st.valUsername,
            })}
            placeholder="Username"
            name="username"
            value={this.state.username}
            onChange={this.onChange}
          />
          {st.valUsername && <div className="invalid-feedback">{st.valUsername}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-field-label">
            Password
          </label>
        <div className="inputContainer">
        <i
          className={this.state.hidden === false ? 'fa fa-eye icon' : 'fa fa-eye-slash icon'}
          onClick={this.toggleShow}> 
        </i>
          <input
            type={this.state.hidden ? 'password' : 'text'}
            className={classnames("form-control form-control-lg form-input-field", {
              "is-invalid": st.valPassword,
            })}
            placeholder="Password"
            name="password"
            value={this.state.password}
            onChange={this.onChange}
          />
         </div>
          {st.valPassword && <div className="invalid-feedback">{st.valPassword}</div>}
        </div>
        
        <div>
          <Checkbox
            type="checkbox"
            wrapper="remember-wrapper"
            name="rememberMe"
            label="Remember Me"
            checked={st.rememberMe}
            onChange={this.handleChange}
          />
          {/* <Link className="fp-link" to="/ForgotPassword">
            Forgot Password?
          </Link> */}
        </div>
        <input type="submit" className="btn btn-info btn-block mt-2 login-button" value="Login" />
        <div>
          <ErrorModal
            onHide={this.handleClose}
            handleClose={this.handleClose}
            show={this.state.show}
            status={this.props.errors.status}
            message={this.props.errors.message}
          />
        </div>
      </form>
    );
    if (this.state.loading === true) {
      form = <Spinner />;
    }

    return (
      <>
        <div className="login-logo-container">
          <img className="login-logo" src={marvelImg} alt="" />
        </div>
        <div className="container" id="login-container">
          <div className="row">
            <div className="col-5" id="login">
                <h1 className="display-4 login-header text-center">Login</h1>
              {form}
            </div>
          </div>
        </div>
        <div className="copyright-wrapper">Copyright © {moment().format('YYYY')} </div>
      </>
    );
  }

}
Login.propTypes = {
  login: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
  errors: state.errors,
});

export default connect(mapStateToProps, { login })(Login);
