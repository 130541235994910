import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";


export class ImageModal extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.show ? (
          <Modal
            style={{ marginTop: 80 }}
            show={this.props.show}
            onHide={this.props.onHide}
            className="ModalDoc"
          >
            <Modal.Body>
              <br />
              <Form.Group as={Col} controlId="formGridUrl">
                <Form.Label className="labels-offers">Add media</Form.Label>
                <br />
                <Form.Row></Form.Row>
                <Form.Row className="row-image-modal">
                  <label for="file-upload"></label>
                  <input
                    accept="image/*"
                    id="file-upload"
                    type="file"
                    onChange={this.props.onChangeImage}
                  />
                  <span className="or-text">
                    or
                  </span>
                  <Form.Group
                    as={Col}
                    controlId="formGridUrl"
                    onChange={this.onChangeUrl}
                  >
                    <Form.Control
                      type="url"
                      id="image-url-input"
                      placeholder=" Paste image URL"
                      onChange={this.props.onChangeUrl}
                      required
                    />
                  </Form.Group>
                </Form.Row>
              </Form.Group>
              <Form.Row style={{ display: "block", marginLeft: "auto", marginRight: 15}}>
                <Button
                  bsPrefix="custom-btn-cancel-modal"
                  size="md"
                  onClick={() => {
                    this.props.removeImage()
                    this.props.onHide()
                  }}>
                  Cancel
                </Button>
                <Button
                  bsPrefix="add-img-btn"
                  size="md"
                  onClick={this.props.onHide}
                >
                  Add
                </Button>
              </Form.Row>
            </Modal.Body>
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

export default ImageModal;
