import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage, getIn } from 'formik';
import Spinner from '../Spinner/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import *  as lodash from "lodash";


export const CustomForm = (props) => {
  if (props.loading) {
    return <Spinner />
  } else {
    return (
      <Formik
        enableReinitialize={true}    // This prop is needed so that we can update form values externally.
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}>
        <Form className='custom-form'>
          {props.children}
        </Form>
      </Formik>
    )
  }
}

export const FormRow = (props) => {
  return (
    <div style={props.style} className='form-row'>
      {props.children}
    </div>
  )
}

export const FormSubHeading = (props) => {
  return (
      <h6 className="mt-4 table-sub-header">{props.children}</h6>
  )
}

export const FormWrapper = (props) => {
  return (
    <div>
      <div className={props.parentClass}>
        <div className="container">
          {props.mainHeading ?
            <div className="row ">
              <FontAwesomeIcon icon={props.icon} size="lg" className="fontawesome-dash title-svg" />
              <h1 className="main-heading">{props.mainHeading}</h1>
            </div>
            : null}
          <div className="row">
            <div className="col-md-12 mt-4">
             {/* <hr /> */}
              <h5 className="text-left table-number">{props.componentHeading}</h5>
              <div className="mt-3">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export const FormButtons = (props) => {
  return (
    <div className="text-right form-group col-md-8 form-button-wrapper">
      <button type="submit"  className='btn solid-button' >{props.btn}</button>
      <Link to={{ pathname: "/Dashboard" }}>
        <button type="button" className="btn outline-button" >Cancel</button>
      </Link>
      <div className="required-text mb-2">{props.text}</div>
    </div>
  )
}

export const FormInput = (props) => {
  return (
    <Field name={props.name} validate={props.validate}>
      {({ field, form: { touched, errors } }) => (
        <div className={props.size ? `form-group ${props.size}` : "form-group col-md-3"} >
          
          {
          props.noLabelText 
            ? <label> </label>
            : <label htmlFor={`${field.name}`}>{props.label ? props.label : lodash.startCase(field.name)}</label>
          }
          <Field
            style={props.style}
            name={field.name} 
            type={props.type ? props.type : "text"}
            placeholder={props.placeholder || props.label || lodash.startCase(field.name)}
            className={`form-control ${getIn(errors, props.name) && getIn(touched, props.name) ? 'is-invalid' : ''}`}
          />
          <ErrorMessage component="div" name={field.name} className="invalid-feedback" />
        </div>
      )}
  </Field>
  )
}

export const FormTextArea = (props) => {
  return (
    <Field name={props.name} validate={props.validate}>
      {({ field, form: { touched, errors } }) => (
        <div className={props.size ? `form-group ${props.size}` : "form-group col-md-3"}>
          <label htmlFor={`${field.name}`}>{props.label ? props.label : lodash.startCase(field.name)}</label>
          <Field
            style={props.style}
            name={field.name} 
            component="textarea"
            style={{ resize: props.resize || "none", width: props.width || "calc(100% - 16px)", height: props.height || 100 }}
            placeholder={props.placeholder || props.label || lodash.startCase(field.name)}
            className={`form-control ${getIn(errors, props.name) && getIn(touched, props.name) ? 'is-invalid' : ''}`}
          />
          <ErrorMessage component="div" name={field.name} className="invalid-feedback" />
        </div>
      )}
  </Field>
  )
}

export const FormCheckbox = (props) => {
  const checkboxSize = {
    height: 20, width: 20, marginBottom: 5
  }

  return (
    <Field name={props.name} validate={props.validate}>
      {({ field, form: { touched, errors } }) => (
        <div className={props.size ? `form-group ${props.size}` : "form-group col-md-3"} style={props.containerStyle}>
          <div className='form-row'>
          <div 
            className="form-group col-md-10"
            style={{ 
              width: "fit-content",
              flexBasis: "auto"
           }} >
              <label htmlFor={`${field.name}`}>{props.label ? props.label : lodash.startCase(field.name)}</label>
              <ErrorMessage component="div" name={field.name} className="invalid-feedback" />
          </div>
          <div 
          className="form-group col-1 col-md-2" 
          style={{ 
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <Field
              style={checkboxSize}
              name={field.name} 
              type="checkbox"
              placeholder={props.placeholder || props.label || lodash.startCase(field.name)}
              className={`form-control ${getIn(errors, props.name) && getIn(touched, props.name) ? 'is-invalid' : ''}`}
            />
          </div>
          </div>
        </div>
      )}
  </Field>
  )
}


export const TermsAndConditions = (props) => {
  const checkboxSize = {
    height: 20, width: 20, marginBottom: 10, minWidth: 15, minHeight: 15
  }

  return (
    <Field name={props.name} validate={props.validate}>
      {({ field, form: { touched, errors } }) => (
        <div className={props.size ? `form-group ${props.size}` : "form-group col-md-3"}>
          <div style={props.style} className='form-row'>
          <div className="form-group col-1 col-sm-1 col-md-1" 
            style={{ 
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>                
                <Field
                  style={checkboxSize}
                  name={field.name}
                  type="checkbox"
                  className={`form-control ${getIn(errors, props.name) && getIn(touched, props.name) ? 'is-invalid' : ''}`} />
            </div>
            <div className="form-group col-10 col-sm-11 col-md-10">
                <label
                style={props.labelStyle}
                htmlFor={`${field.name}`}>
                  The customer has read and accepts the <a style={{color: "#386896"}} href="https://app.marvelmarine.gr/privacy_policy/">Privacy Policy</a> and the <a style={{color: "#386896"}} href="https://app.marvelmarine.gr/tou/">Terms And Conditions</a>
                </label>

                {/* Unfortunately, I couldn't get this to work with ErrorMessage component. */}
                {getIn(errors, props.name) && 
                <div style={{ fontSize: "80%", fontColor:"#dc3545"}}>{
                  <p style={{fontSize: "12.8px", color: "#dc3545"}}>{getIn(errors, props.name)}</p>}
                </div>}

            </div>          
          </div>
        </div>
      )}
  </Field>
  )
}



export const FormDropdown = (props) => {
  const createOptionsHTML = () => {
    if (props.options) {
      let optionsHTML = [];
      optionsHTML.push(<option value='NONE'>{props.label ? `Choose ${props.label}` : `Choose ${lodash.startCase(props.name)}`}</option>)
      props.options.forEach(i => { optionsHTML.push(<option value={Object.keys(i)}>{Object.values(i)}</option>)});
      return optionsHTML
    };
  }

  return (
    <Field name={props.name} validate={props.validate}>
      {({ field, form: { touched, errors } }) => (
        <div className={props.size ? `form-group ${props.size}` : "form-group col-md-3"}>
          <label htmlFor={`${field.name}`}>{props.label ? props.label : lodash.startCase(field.name)}</label>
          <Field
            disabled={props.disable}
            name={field.name} 
            as='select' 
            className={`form-control ${getIn(errors, props.name) && getIn(touched, props.name) ? 'is-invalid' : ''}`}
          >
            {createOptionsHTML()}
            </Field>
          <ErrorMessage component="div" name={field.name} className="invalid-feedback" />
        </div>
      )}
  </Field>
  )
}