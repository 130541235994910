import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../Redux/actions/Users/securityActions";
import DrawerToggleButton from "../UI/Navigation/SideDrawer/DrawerToggleButton";

class HeaderTest extends Component {
  logout = () => {
    this.props.logout();
    window.location.href = "/";
  };

  render() {
    const { validToken, user } = this.props.security;
    return (
      <div id="header-container">
        {validToken && user ? (
          <div>
            <div className="row" id="header-row">
              <div className="col-11">
                <div className="toolbar__toggle-button" style={{height: 70}}>
                  <DrawerToggleButton click={this.props.drawerClickHandler} />
                </div>
              </div>
              <div className="col-1 header-options" style={{marginTop: "auto", marginBottom: "auto"}}>
                <Link to="/logout" onClick={this.logout}>
                  Logout
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12"></div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps, { logout })(HeaderTest);
