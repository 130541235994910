import {
    FETCH_POSTS_START,
    FETCH_POSTS_SUCCESS,
    FETCH_POSTS_FAILURE,
  } from "../../actions/types";
  
  export default (state = null, action) => {
    switch (action.type) {
      case FETCH_POSTS_START:
        return {
          ...state,
          isLoading: true,
        };
      case FETCH_POSTS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          posts: action,
        };
      case FETCH_POSTS_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
