import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";

import OffersList from "./OffersList";
import PostsList from "./PostsList";

export class ListOffersNews extends Component {
  render() {
    return (
      <React.Fragment>
        <FontAwesomeIcon
          icon={faFileUpload}
          size="sm"
          className="fontawesome-sidemenu"
          id="fonticon-list-news-off"
        />
        <h5 className="header-lists-news-off">News & Offers</h5>
        <div className="grid-container-lists">
          <div className="news-list">
            <PostsList />
          </div>
          <div className="offers-list">
            <OffersList />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ListOffersNews;
