import React, { Component } from "react";
import { fetchTechnicians } from "../../../Redux/actions/Technicians/fetchTechnicianAction";
import { fetchCustomers } from "../../../Redux/actions/Customers/fetchCustomerAction";
import { fetchAdmins } from "../../../Redux/actions/Admins/fetchAdminAction";
import {fetchBookings} from '../../../Redux/actions/Bookings/fetchBookingAction'
import IncidentsMap from "../Admin/ResourceMGT/Technicians/IncidentsMap";
import Notifications from "../../Screens/Admin/ResourceMGT/Notifications/Notifications";
import {
  showMembers,
  showRegister,
  closeRegisterMember,
  showTechnicians,
} from "../../../Redux/actions/UI/sideMenuActions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { TechInstance } from '../../../config';


class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
    };
  }
  componentDidMount = async () => {
    this.props.fetchTechnicians();
    this.props.fetchAdmins();
    this.props.fetchCustomers();
    console.log(this.props)
    this.getTechnicians();
    this.props.fetchBookings()
  };
  
  getTechnicians = async () => {
      await TechInstance.get(`technician/`);
  };

  render() {
    return (
      <div className="grid-container">

        <div className="Header">
          <FontAwesomeIcon
            icon={faHome}
            size="sm"
            className="fontawesome-dash"
            style={{ float: "left" , marginLeft: "5px"}}
          />
          <h5 className="dash-heading" style={{ float: "left" }}>
            Dashboard
          </h5>
        </div>

        <div className="Customers">
          <h2 className="dash-secondary-heading">CUSTOMERS</h2>
          <div className="text-dashboard">
          Register a new customer or view the list of the current customers .
          </div>
          <div className="btn-group dropdown-padding dashboard-buttons-container">
            <Link
              to="/NewCustomer"
              onClick={() => {
                this.props.showRegister();
              }}
            >
              <button
                type="button"
                data-testid="org-button"
                className=" dashboard-register-button"
              >
                New Customer
              </button>
            </Link>
            <Link
              to="/CustomerList"
              onClick={() => {
                this.props.showMembers();
              }}
            >
              <button
                type="button"
                data-testid="org-button"
                className=" dash-view-button"
              >
                View List
              </button>
            </Link>
          </div>
        </div>

        <div className="Administrators">
          <h2 className="dash-secondary-heading">ADMINISTRATORS</h2>
          <div className="text-dashboard">
          Register a new administrator or view the list of the  administrators .
          </div>
          <div className="btn-group dropdown-padding dashboard-buttons-container">
            <Link
              to="/NewAdmin"
              onClick={() => {
                this.props.showRegister();
              }}
            >
              <button
                type="button"
                data-testid="user-button"
                className="dashboard-register-button"
              >
                New Admin
              </button>
            </Link>
            <Link
              to={{ pathname: "/AdminList", populate: false }}
              onClick={() => {
                this.props.showMembers();
              }}
            >
              <button
                type="button"
                data-testid="user-button"
                className="dash-view-button"
              >
                View List
              </button>
            </Link>
          </div>
        </div>

        <div className="Technicians">
          <h2 className="dash-secondary-heading">TECHNICIANS</h2>
          <div className="text-dashboard">
            Register a new technician or view the list of the current technicians .
          </div>
          <div className="btn-group dropdown-padding dashboard-buttons-container">
            <Link
              to="/RegisterTechnician"
              onClick={() => {
                this.props.showRegister();
              }}
            >
              <button type="button"
                data-testid="org-button" 
                className="dashboard-register-button"
              >
                New Technician
              </button>
            </Link>
            <Link
              to="/TechnicianMGT"
              onClick={() => {
                this.props.showMembers();
              }}
            >
              <button type="button" data-testid="org-button" className="dash-view-button">
                View List
              </button>
            </Link>
          </div>
        </div>

        <div className="Map">
          <IncidentsMap />
        </div>
        <div style={{position: "relative", paddingBottom: 40}} className="Notifications">
          <Notifications />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    technicians: state.technicians,
    admins: state.admins,
    customers: state.customers,
    bookings: state.bookings
  };
};

export default connect(mapStateToProps, {
  fetchBookings,
  fetchTechnicians,
  fetchAdmins,
  fetchCustomers,
  showMembers,
  showRegister,
  showTechnicians,
  closeRegisterMember,
})(Dashboard);
