import {
    FETCH_TECHNICIANS_START,
    FETCH_TECHNICIANS_SUCCESS,
    FETCH_TECHNICIANS_FAILURE,
    EDIT_TECHNICIAN,
    TECHNICIAN_SELECTED,
    DELETE_TECHNICIAN,
  } from "../types";
  import history from "../../../history";
  import { TechInstance } from "../../../config";
  
  const fetchTechniciansStart = () => {
    return {
      type: FETCH_TECHNICIANS_START,
    };
  };
  
  const fetchTechniciansSuccess = (technicians) => {
    return {
      type: FETCH_TECHNICIANS_SUCCESS,
      technicians,
    };
  };
  
  const fetchTechniciansFailure = (error) => {
    return {
      type: FETCH_TECHNICIANS_FAILURE,
      error,
    };
  };
  
  const fetchTechnicianDetails = () => {
    const response = TechInstance.get("technician/");
    return response;
  };
  
  export const fetchTechnicians = () => (dispatch) => {
    dispatch(fetchTechniciansStart());
    return fetchTechnicianDetails().then(
      (response) => dispatch(fetchTechniciansSuccess(response.data.data)),
      (error) => dispatch(fetchTechniciansFailure(error))
    );
  };
  
  export const selectTechnician = (id) => {
    return {
      type: TECHNICIAN_SELECTED,
      id: id,
    };
  };
  
  export const deleteTechnician = (id) => {
    return async (dispatch) => {
      const response = await TechInstance.delete(`technician/${id}/`);
      dispatch({ type: DELETE_TECHNICIAN, payload: response.data });
      history.push("/");
    };
  };
  
  export const editTechnician = (id) => {
    return async (dispatch) => {
      const response = await TechInstance.get(`technician/${id}/`);
      dispatch({ type: EDIT_TECHNICIAN, payload: response.data });
      history.push({
        pathname: "/RegisterTechnician",
        prePopulate: response.data.data,
        populate: true,
      });
    };
  };