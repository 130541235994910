import { DELETE_OFFER } from "../../actions/types";

export default (state = null, action) => {
  switch (action.type) {
    case DELETE_OFFER:
      return {
        ...state,
        deleteOffer: action,
      };
    default:
      return state;
  }
};
