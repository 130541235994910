import { combineReducers } from "redux";
import organizationErrorReducer from "./Organizations/organizationErrorReducer";
import loginReducer from "./Users/loginReducer";

import toggleModalReducer from "./UI/toggleModalReducer";
import toggleDispatchModalReducer from "./UI/toggleDispatchModalReducer";
import toggleNewsModalReducer from "./UI/toggleNewsModalReducer";

import sideMenuReducer from "./UI/sideMenuReducer";
import sideMenuClearReducer from "./UI/sideMenuClearReducer";
import fetchTechniciansReducer from "./Technicians/fetchTechniciansReducer";
import editTechnicianReducer from "./Technicians/editTechnicianReducer";
import deleteTechnicianReducer from "./Technicians/deleteTechnicianReducer";
import selectTechnicianReducer from "./Technicians/selectTechnicianReducer";

import fetchCustomersReducer from "./Customers/fetchCustomersReducer";
import fetchCustomersRegistrationReducer from "./Customers/fetchCustomersRegistrationReducer";
import editCustomerReducer from "./Customers/editCustomerReducer";
import deleteCustomerReducer from "./Customers/deleteCustomerReducer";
import deleteDocumentReducer from "./Customers/deleteDocumentReducer";
import selectCustomerReducer from "./Customers/selectCustomerReducer";

import fetchAdminsReducer from "./Admins/fetchAdminsReducer";
import editAdminReducer from "./Admins/editAdminReducer";
import deleteAdminReducer from "./Admins/deleteAdminReducer";
import selectAdminReducer from "./Admins/selectAdminReducer";

import fetchBookingsReducer from "./Bookings/fetchBookingsReducer";
import fetchBookingsRegistrationReducer from "./Bookings/fetchBookingsRegistrationReducer";
import editBookingReducer from "./Bookings/editBookingReducer";
import deleteBookingReducer from "./Bookings/deleteBookingReducer";
import selectBookingReducer from "./Bookings/selectBookingReducer";

import fetchOffersReducer from "./Offers/fetchOffersReducer";
import editOfferReducer from "./Offers/editOfferReducer";
import deleteOfferReducer from "./Offers/deleteOfferReducer";
import selectOfferReducer from "./Offers/selectOfferReducer";

import fetchPostsReducer from "./Posts/fetchPostsReducer";
import editPostReducer from "./Posts/editPostReducer";
import deletePostReducer from "./Posts/deletePostReducer";
import selectPostReducer from "./Posts/selectPostReducer";

import userAlertReducer from "./userAlertReducer"

// combinereducers is used when multiple reducers are present
export default combineReducers({
  errors: organizationErrorReducer,
  security: loginReducer,

  technicians: fetchTechniciansReducer,
  editTechnician: editTechnicianReducer,
  deleteTechnician: deleteTechnicianReducer,
  selectTechnician: selectTechnicianReducer,
  selectedTechnician: selectTechnicianReducer,

  toggleModal: toggleModalReducer,
  toggleNewsModal: toggleNewsModalReducer,
  toggleDispatchModal: toggleDispatchModalReducer,

  sideMenu: sideMenuReducer,
  sideMenuClear: sideMenuClearReducer,

  customers: fetchCustomersReducer,
  fetchCustomersRegistration: fetchCustomersRegistrationReducer,
  editCustomer: editCustomerReducer,
  deleteCustomer: deleteCustomerReducer,
  selectedCustomer: selectCustomerReducer,
  selectCustomer: selectCustomerReducer,
  deleteDocument: deleteDocumentReducer,

  bookings: fetchBookingsReducer,
  editBooking: editBookingReducer,
  deleteBooking: deleteBookingReducer,
  selectedBooking: selectBookingReducer,
  selectBooking: selectBookingReducer,
  fetchBookingsRegistration: fetchBookingsRegistrationReducer,

  admins: fetchAdminsReducer,
  editAdmin: editAdminReducer,
  deleteAdmin: deleteAdminReducer,
  selectedAdmin: selectAdminReducer,
  selectAdmin: selectAdminReducer,

  offers: fetchOffersReducer,
  editOffer: editOfferReducer,
  deleteOffer: deleteOfferReducer,
  selectedOffer: selectOfferReducer,
  selectOffer: selectOfferReducer,

  posts: fetchPostsReducer,
  editPost: editPostReducer,
  deletePost: deletePostReducer,
  selectedPost: selectPostReducer,
  selectPost: selectPostReducer,

  userAlert: userAlertReducer,

});
