import React, { Component } from "react";

import { Link } from "react-router-dom";
import RegisterTechnicianMap from "./RegisterTechnicianMap";

import { TechInstance, GoogleAPIKey, SearchBoxId } from "../../../../../config";
import { Formik, Form } from "formik";
import {
  FormRow,
  FormInput,
  FormCheckbox,
  FormTextArea,
  TermsAndConditions
} from "../../../../../components/UI/Form/Form";
import * as validation from "../../../../../components/UI/Form/formValidation";
import { fetchTechnicians } from "../../../../../Redux/actions/Technicians/fetchTechnicianAction";
import { connect } from "react-redux";
import ErrorModal from "../../../../../components/UI/Modal/ErrorModal";
import Geocode from "react-geocode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from "@fortawesome/free-solid-svg-icons";

Geocode.setApiKey(GoogleAPIKey);
Geocode.enableDebug();

class TechnicianForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      username: "",
      phoneNumber: "",
      email: "",
      capableOfInstallation: false,
      capableOfSurvey: false,
      otherCapabilities: "",
      details: "",
      location: "",
      lat: "",
      lng: "",

      show: false,
      error: {},
      termsAndConditions: false,
      showAlert: false,
      edit: false,
    };
  }

  async componentDidMount() {
    if (this.props.location.prePopulate) {
      this.setState({
        firstName: this.props.location.prePopulate.first_name,
        lastName: this.props.location.prePopulate.last_name,
        email: this.props.location.prePopulate.email_address,
        phoneNumber: this.props.location.prePopulate.telephone_number,
        capableOfSurvey: this.props.location.prePopulate.capable_for_survey,
        capableOfInstallation: this.props.location.prePopulate
          .capable_for_installation,
        details: this.props.location.prePopulate.details,
        otherCapabilities: this.props.location.prePopulate.other_capabilities,
        lat: this.props.location.prePopulate.lat,
        lng: this.props.location.prePopulate.lng,
        location: this.state.location,
        edit: true,
      });
    }
    await this.getLatLng();

    // If we're editing an existing technician (so, not creating a new technician),
    if (this.props.location.prePopulate) {
      // wait for the search box element to render,
      while(!document.getElementById(SearchBoxId)) {
        await new Promise(r => setTimeout(r, 250));
      }
      // and insert technician's location into the search box (rendered by RegisterTechnicianMap component).
      let searchBox = document.getElementById(SearchBoxId);
      searchBox.value = this.state.location;
    }
  }

  async getLatLng() {
    if (this.props.location.prePopulate) {
      Geocode.setApiKey(GoogleAPIKey);
      Geocode.enableDebug();
      return await Geocode.fromLatLng(
        this.props.location.prePopulate.lat,
        this.props.location.prePopulate.lng
      ).then(
        (response) => [
          response.results[0].formatted_address,
          this.setState({ location: response.results[0].formatted_address }),
        ],
        (error) => console.error(error)
      );
    }
  }

  async getLocationCoordinates(location) {
    Geocode.setApiKey(GoogleAPIKey);
    Geocode.enableDebug();
    return await Geocode.fromAddress(location).then(
      (response) => [
        response.results[0].geometry.location.lat,
        response.results[0].geometry.location.lng,
      ],
      (error) => console.error(error)
    );
  }

  setFormValues = (tech) => {
    this.setState({ firstName: tech.first_name })
    this.setState({ lastName: tech.last_name })
    this.setState({ details: tech.details })
    this.setState({ capableOfInstallation: tech.capable_for_installation })
    this.setState({ capableOfSurvey: tech.capable_for_survey })
    this.setState({ otherCapabilities: tech.other_capabilities })
    this.setState({ lat: tech.lat })
    this.setState({ lng: tech.lng })
    this.setState({ email: tech.email_address })
    this.setState({ phoneNumber: tech.telephone_number })
  }

  onSubmit = async (formValues) => {

    let locationSearchBox = document.getElementById("tech-form-map");
    if(!locationSearchBox.value) {
      let warning = document.getElementById("register-tech-map-warning");
      warning.style.display = "block";
      return
    } else {
      let warning = document.getElementById("register-tech-map-warning");
      warning.style.display = "none";
      this.setState({ location: locationSearchBox.value });
    }

    try {
      [formValues.lat, formValues.lng] = await this.getLocationCoordinates(locationSearchBox.value);
    } catch (error) {
      console.log("Could not get coordinates from location.");
      this.setState({ error: error, show: true });
    }

    this.state = formValues;
    const technician = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      details: this.state.details,
      capable_for_installation: this.state.capableOfInstallation,
      capable_for_survey: this.state.capableOfSurvey,
      other_capabilities: this.state.otherCapabilities,
      lat: this.state.lat,
      lng: this.state.lng,
      location: this.state.location,
      email_address: this.state.email,
      telephone_number: this.state.phoneNumber,
    };
    
    try {
      if (this.props.location.prePopulate) {
        await TechInstance.patch(`technician/${this.props.location.prePopulate.id}/`, technician);
        this.setState({ showAlert: true });
        this.setFormValues(technician)
      } else {
        await TechInstance.post("technician/", technician).then((res) => {
          if (res.status === 201) {
            this.setState({ showAlert: true });
            this.setFormValues(technician);
          }
        });
      }
    } catch (error) {
      console.log("Register Technician API error");
      this.setState({ error: error, show: true });
    }
  };

  clickInvisibleButton = () => {
    // To comply with design, the submit and cancel button needs to be outside
    // of Formik. So we have an invisible submit button and a visible one.
    // The visible buttons will click the invisible Formik button (with this method). 
    // This way we can comply with the design without restructuring this whole thing.

    const button = document.getElementById("tech-form-submit");
    button.click();
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    let form = (
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        initialValues={{ ...this.state }}
        onSubmit={(formValues) => this.onSubmit(formValues)}
      >
        {({ values }) => (
          <Form className="custom-form">
            { this.state.showAlert ? 
              (<div className="alert alert-success" role="alert"> 
              Technician successfully  { this.props.location.prePopulate ? "edited" : "created" } 
              </div>) : null }
            <FormRow>
              <FormInput
                name="firstName"
                placeholder="First Name"
                label="Full Name"
                size=" col-sm-6"
                validate={(value) => validation.inputIsRequired("Name", value)}
              />
              <FormInput
                name="lastName"
                style={{ marginTop: 5 }}
                noLabelText
                size="col-sm-6"
                validate={(value) =>
                  validation.inputIsRequired("Last name", value)
                }
              />
            </FormRow>
            
            <FormRow>
              <FormInput
                name="email"
                size="col-sm-6"
                validate={(value) =>
                  validation.email(
                    value,
                    this.props.location.prePopulate
                      ? this.props.location.prePopulate.email
                      : null
                  )
                }
              />
              <FormInput
                name="phoneNumber"
                label="Telephone"
                size="col-sm-6"
                validate={(value) => validation.phoneNumber(value)}
              />
            </FormRow>
            
            <FormRow  id="checkboxes-rows">
              <FormCheckbox 
                size="col-sm-6 checkbox-row"
                label="Capable of Survey"
                name="capableOfSurvey"
              />
            
              <FormCheckbox 
                size="col-sm-6 checkbox-row"
                label="Capable of Installation"
                name="capableOfInstallation"
              />
            </FormRow>

            <FormRow>
              <FormTextArea name="otherCapabilities" component="textarea" size="col-sm-12 col-md-12"
              validate={(value) => validation.inputIsRequired("Other Capabilities", value,  "Please enter other capabilities.")} />
            </FormRow>

            <FormRow>
              <FormTextArea name="details" component="textarea" size="col-md-12" height="60px"
                validate={(value) => validation.inputIsRequired("Details", value, "Details are required.")} />
            </FormRow>

            <FormRow>
              <TermsAndConditions
                size="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                name="theCustomerHasReadAndAcceptsThePrivacyPolicyAndTheTermsAndConditions"
                validate={(value) =>
                  validation.termsAndConditionsTechnicianForm(value, values)
                }
              />
            </FormRow>

            <FormRow style={{display: "none"}} >
              <div className="text-right form-group col-md-10 col-xl-10 col-xxl-11 form-button-wrapper-tech">
                <button type="submit" id="tech-form-submit"  className="btn solid-button">{this.state.edit === true ? "Edit Technician" : "Register Technician"}</button>
                <Link to={{ pathname: "/Dashboard" }}>
                  <button type="button" id="tech-form-cancel" className="btn outline-button">Cancel</button>
                </Link>
              </div>
            </FormRow>

            <ErrorModal
              onHide={this.handleClose}
              handleClose={this.handleClose}
              show={this.state.show}
              error={this.state.error}
            />
          </Form>
        )}
      </Formik>
    );

    return (
      <>
        <FontAwesomeIcon
          icon={faTools}
          size="sm"
          className="fontawesome-sidemenu"
          id="fonticon-list"
        />
        <h5 className="header-lists">Technicians</h5>
        <div className="grid-container-tech">
          <div className="form">
            <h6 className="tech-header">{this.state.edit === true ? "EDIT TECHNICIAN" : "NEW TECHNICIAN"} </h6>
            {form}
          </div>
          <div className="map-tech">
            <div className="map-form">
              <RegisterTechnicianMap 
                lat={this.state.lat}
                lng={this.state.lng}
              />
            </div>
          </div>

          <div className="tech-form-buttons text-right form-group col-md-10 col-xl-10 col-xxl-11">
            <button onClick={this.clickInvisibleButton} type="submit"  className="btn solid-button tech-form-button">{this.state.edit === true ? "Edit Technician" : "Register Technician"}</button>
            <Link to={{ pathname: "/Dashboard" }}>
              <button type="button" className="btn outline-button tech-form-button">Cancel</button>
            </Link>
          </div>


          <Link to="/RegisterTechnician"></Link>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    security: state.security,
    technicians: state.technicians,
  };
};

export default connect(mapStateToProps, {
  fetchTechnicians,
})(TechnicianForm);
