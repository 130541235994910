import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {AdminInstance, PostInstance, OfferInstance} from '../../../../../config'
import {
  fetchAdmins,
  editAdmin,
  selectAdmin,
  deleteAdmin,
} from "../../../../../Redux/actions/Admins/fetchAdminAction";
import { showModal, hideModal } from "../../../../../Redux/actions/UI/toggleModalAction";
import DataTable from "react-data-table-component";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import arrow from "../../../../../assets/arrow.svg";
import Edit from "../../../../../assets/edit.svg";
import Delete from "../../../../../assets/delete.svg";
import Password from '../../../../../assets/password.svg'
import orderBy from "lodash/orderBy";
import Dropdown from "react-bootstrap/Dropdown";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCardAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

import {
  fetchPosts,
} from "../../../../../Redux/actions/Posts/fetchPostActions";

class AdminList extends Component {
  constructor() {
    super();
    this.state = {
      filterText: "",
      showAlert: false,
    };
  }

  async componentDidMount() {
    this.props.fetchAdmins();
    await PostInstance.get("/announcement")
    await OfferInstance.get("offers/");
    this.props.fetchPosts();
    await console.log(this.props)
  }


  handleChange = (event) => {
    this.setState({ filterText: event.target.value });
  };

  passwordReset = async (id)=>{
    await AdminInstance.get(`administrator/${id}/reset-password`).then((res) => {
      if (res.status === 200) {
        this.setState({ showAlert: true })
      }
    })
  }

  modalHandler() {
    if (this.props.modal) {
      return (
        <MDBContainer
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "0px 0px 0px #888888",
            padding: "1%",
          }}
        >
          <MDBModal isOpen={this.props.modal} toggle={this.props.modal} centered size="sm">
            <MDBModalHeader>Delete</MDBModalHeader>
            <MDBModalBody>Are you sure ?</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="light" onClick={this.props.hideModal} size="sm">
                Cancel
              </MDBBtn>
              <MDBBtn
                color="danger"
                onClick={() => {
                  if (this.selectAdmin !== null) {
                    this.props.deleteAdmin(this.props.selectedAdmin.id);
                    this.props.hideModal();
                  }
                }}
                size="sm"
              >
                Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
    }
  }

  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field]) {
        return row[field].toLowerCase();
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  actionOptions = (row) => {
    return (
      <>
        <Dropdown.Item onClick={() => this.props.editAdmin(row.id)}>
          <div className="table-edit">
            <img src={Edit} alt="" /> Edit
          </div>
        </Dropdown.Item>
        <Dropdown.Item
         onClick={()=>this.passwordReset(row.id)}
        >
          <div className="table-password">
            <img src={Password} alt="" /> Reset Pass
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            this.props.selectAdmin(row.id);
            this.props.showModal();
          }}
        >
          <div className="table-delete">
            <img src={Delete} alt="" /> Delete
          </div>
        </Dropdown.Item>
      </>
    );
  };

  renderUsers() {
    if (this.props.admins !== null && this.props.admins.isLoading === false) {
      return this.props.admins.admins.admins.map((admin) => {
        return {
          id: admin.id,
          username: admin.user.username,
          name: admin.user.first_name,
          lastName: admin.user.last_name,
          email: admin.user.email,
          edit: (
            <MDBBtn color="primary" size="sm" onClick={() => this.props.editAdmin(admin.id)}>
              Edit
            </MDBBtn>
          ),
          delete: (
            <MDBBtn
              color="danger"
              size="sm"
              onClick={() => {
                this.props.selectAdmin(admin.id);
                this.props.showModal();
              }}
            >
              Delete
            </MDBBtn>
          ),
          password:(
            <MDBBtn
            color="primary"
            size="sm"
          >
            Reset Password
          </MDBBtn> 
          )
        };
      });
    }
  }

  

  render() {
    let filteredData;
    const { filterText } = this.state;
    if (this.props.admins !== null && this.props.admins.isLoading === false) {
      filteredData = this.renderUsers().filter(
        (item) =>
          item.username.toLowerCase().includes(filterText.toLowerCase()) ||
          item.name.toLowerCase().includes(filterText.toLowerCase()) ||
          item.lastName.toLowerCase().includes(filterText.toLowerCase()) ||
          item.email.toLowerCase().includes(filterText.toLowerCase())
      );
    }

    const columns = [
      {
        name: "Username",
        selector: "username",
        grow: 1,
        sortable: true,
      },
      {
        name: "Name",
        selector: "name",
        grow: 1,
        sortable: true,
      },
      {
        name: "Last Name",
        selector: "lastName",
        grow: 1,
        sortable: true,
      },
      {
        name: "Email",
        selector: "email",
        grow: 1,
        sortable: true,
      },
      {
        cell: (row) => (
          <Dropdown>
            <Dropdown.Toggle id="dropdown-action-user-org" style={{marginTop:"13px"}}>
              <img src={arrow} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="edit-table-wrapper">{this.actionOptions(row)}</Dropdown.Menu>
          </Dropdown>
        ),
        selector: "action",
        allowOverflow: true,
        button: true,
        right: true,
      },
    ];

    if (this.props.admins !== null && this.props.admins.isLoading === true) {
      return <Spinner />;
    } else {
      return (
        <div >
          <FontAwesomeIcon icon={faIdCardAlt} size="sm" className="fontawesome-sidemenu" id="fonticon-list" />
          <h5 className="header-lists">  Admins</h5>
          <row>

          </row>
          <div className="white-background-customers">
          {this.state.showAlert === true ? (
              <div className="alert alert-success" role="alert">
                Password successfully updated - new password can be accessed via email</div>) 
                : null
            }
          <span className="list-title">ADMIN LIST</span>
        
            <Link to="/NewAdmin">
              <button type="button" data-testid="org-button" className="dashboard-register-button" id="register-button">
               New Admin
              </button>
            </Link>
          <div className="table-search-wrapper">
            <input
              className="table-search"
              placeholder="   Search Admins"
              value={this.filter}
              onChange={this.handleChange}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination={true}
            paginationPerPage={4}
            paginationRowsPerPageOptions={[4, 10, 15]}
            sortFunction={this.customSort}
          />
          </div>
         
          <div>{this.modalHandler()}</div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
   return {
     posts: state.posts,
    modal: state.toggleModal.show,
    admins : state.admins ,
    security: state.security,
    selectAdmin: state.selectAdmin,
    editAdmin: state.editAdmin,
    deletedAdmin: state.deletedAdmin,
    selectedAdmin: state.selectedAdmin,
   };
 };
 
 const mapDispatchToProps = (dispatch) => {
   return bindActionCreators(
     {fetchPosts, fetchAdmins,editAdmin, selectAdmin, deleteAdmin, showModal, hideModal },
     dispatch
   );
 };
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminList);
