import { SHOW_MODAL, HIDE_MODAL, SHOW_NEWS_MODAL, HIDE_NEWS_MODAL } from "../types";

export const showModal = () => {
  return {
    type: SHOW_MODAL,
  };
};

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
  };
};

export const showNewsModal = () => {
  return {
    type: SHOW_NEWS_MODAL,
  };
};

export const hideNewsModal = () => {
  return {
    type: HIDE_NEWS_MODAL,
  };
};
